<!-- <script>
    import { createForm } from "svelte-forms-lib";
    import * as yup from "yup";



  const { form, errors, state, handleChange, handleSubmit } = createForm({
      initialValues: {
        name: "",//.required(),
        description:"",
        image: "",
        tags: "",
        collaberate: false

      },
      validationSchema: yup.object().shape({
        name: yup.string(),//.required(),
        image: yup.string(),
        description: yup.string(),
        collaberate: yup.boolean(),
        image: yup.string(),
      }),
      onSubmit: values => {

          console.log('vals',values )

        // fetch( backendUrl + '/private/product/'+ id, {
        //     method: 'PUT', // or 'PUT'
        //     headers: {
        //       'Content-Type': 'application/json',
        //       'Authorization':  'Bearer '+ sessionStorage.getItem('jwt'),
        //     },
        //     body: JSON.stringify(values),
        // }).then(()=>{
        //   window.location.href = "/products_new";
        // })

      }
    });
</script>

<style>
    .discovery-form{
        background: white;
    }
</style>

<div class='discovery-form'>
    <h1>fdfsfd</h1>

    <form on:submit={handleSubmit}>

    <label for="userId">userId</label>
      <input
        id="name"
        userId="name"
        on:change={handleChange}
        on:blur={handleChange}
        bind:value={$form.name}
        readonly
      />
      {#if $errors.name}
        <small>{$errors.name}</small>
      {/if}

      jkj

      <label for="description">description</label>
      <input
        id="description"
        description="description"
        on:change={handleChange}
        on:blur={handleChange}
        bind:value={$form.description}
        readonly
      />
      {#if $errors.description}
        <small>{$errors.description}</small>
      {/if}



      <button class='submit' type="submit">submit</button>


    </form>
</div> -->


  <script>
    import { createForm } from "svelte-forms-lib";
    import * as yup from "yup";
    import Tags from 'svelte-tags-input'

    import ImageUploader from '../miniComponents/ImageUploader.svelte'

    const backendUrl = '__backendUrl__';

    const suddgestTags = [
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina"
    ];

    let schema = yup.mixed();

    const { form, errors, handleChange, handleSubmit } = createForm({
      initialValues: {
        name: "",//.required(),
        description:"",
        image: "",
        tags: "",
        etsy: "",
        instaId: "",
        siteUrl:"",

        collaborate: false
      },
      validationSchema: yup.object().shape({
        name: yup.string(),//.required(),
        image: yup.string(),
        tags: schema.notRequired(),
        etsy: schema.notRequired(),
        instaId: schema.notRequired(),
        siteUrl: schema.notRequired(),
        description: yup.string(),
        image: yup.string(),
        collaborate:  schema.notRequired(),
      }),
      onSubmit: values => {
        values.links = {
          etsy: values.etsy,
          instaId: values.instaId,
          siteUrl: values.siteUrl,
        }

        console.log(values);

        (()=>{

          console.log('RUN',backendUrl);


          // fetch('http://example.com/movies.json')
          // .then(response => response.json())
          // .then(data => console.log(data));




          fetch( backendUrl + '/public/clientProfile', {
              method: 'POST', // or 'PUT'
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(values),
          }).then((res)=>{
            // window.location.href = "/products_new";
            if(res.status === 201){
                submitted = true;
            }

             console.log('sent')
             console.log(res)
          })


        })();






      }
    });

    const handleTags = (event) =>{
      $form.tags = event.detail.tags;
    }

    let submitted = false;



  </script>

  <style>
    .discovery-form{
      background: white;
      overflow: auto;
      margin: 7px;
      margin: 7px;
      color: #272929;
      border-radius: 14px;
      margin-bottom: 0px;
      max-width: 670px;
    }

    :global(.svelte-tags-input-layout){
      max-width:238px
    }

    .description{
      width:100%;
      max-width:238px
    }

    .submited{
      padding: 30px;
    }

   
</style>

  <div class='discovery-form'>

  <div class='input-group'>
  </div>
{#if submitted}
<div class='submited'>
  <h2>Thank you for your submission, we will have a look and add you on soon. 🤩</h2>
</div>

{/if}

{#if !submitted}

  <form  on:submit={handleSubmit}>

    <div class='input-group'>
      <label for="name">Business name</label>
      <input
        onkeydown="return event.key != 'Enter';"
        id="name"
        name="name"
        on:change={handleChange}
        bind:value={$form.name}/>
    </div>

    {#if $errors.name}
      <small>{$errors.name}</small>
    {/if}

    

      
    <div class='input-group'>
      <label for="name">Description (700 characters)</label>
      <textarea
        id="description"
        name="description"
        class="description"
        maxlength="700"
        on:change={handleChange}
        bind:value={$form.description}
      />
    </div>

    <div class='input-group'>

      <label for="image">Image
      <ImageUploader
        onkeydown="return event.key != 'Enter';"
        aspectRatio={(8/5)} 
        id="image"
        image="image"
        hiddenInput={true}
        on:change={handleChange}
        on:blur={handleChange}
        bind:value={$form.image}
      />
    </div>



    <!-- <div class='input-group'>
      <label for="name">Etsy Shop Link</label>
      <input
        onkeydown="return event.key != 'Enter';"
        id="etsy"
        name="etsy"
        class="etsy"
        on:change={handleChange}
        bind:value={$form.etsy}
      />
    </div> -->

    <div class='input-group'>
      <label for="name">Insta username</label>
      <input
        onkeydown="return event.key != 'Enter';"
        id="instaId"
        name="instaId"
        class="instaId"
        on:change={handleChange}
        bind:value={$form.instaId}
      />
    </div>
    

    <div class='input-group'>
      <label for="name">Site URL</label>
      <input
        onkeydown="return event.key != 'Enter';"
        id="siteUrl"
        name="siteUrl"
        class="siteUrl"
        on:change={handleChange}
        bind:value={$form.siteUrl}
      />
    </div>

    <div class='input-group'>

      <label for="tags">Tags</label>
      <!-- <input
        id="tags"
        name="tags"
        on:change={handleChange}
        bind:value={$form.tags}
        /> -->
        <Tags
          on:tags={handleTags}
          addKeys={[9,32,188,13]}
          maxTags={6}
          allowPaste={true}
          splitWith={","}
          onlyUnique={true}
          removeKeys={[27]}
          autoComplete={suddgestTags}
          name={"tags"}
          id={"tag-input"}
          allowBlur={true}
          disable={false}
          minChars={3}
      />
      <div>
      Example Tags: family, craft, handmade, candles
      </div>

    </div>

    <div class='input-group'>

      <label for="collaborate">I am looking for a live buddy</label>
      <input
        id="collaborate"
        type="checkbox" 
        name="collaborate"
        on:change={handleChange}
        bind:checked={$form.collaborate}/>
    </div>

    
      



    <button type="submit">Submit</button>
  </form>
 {/if}
  </div>