

<svelte:options immutable={true} />




<svelte:head>
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.0/css/all.css">
</svelte:head>

<style global lang="scss">


$gray-darker:               #444444;
$gray-dark:                 #696969;
$gray:                      #999999;
$gray-light:                #cccccc;
$gray-lighter:              #ececec;
$gray-lightest:             lighten($gray-lighter,4%);

*,
*::before,
*::after { 
  box-sizing: border-box;
}



select {
    font-size: 15px;
    padding: 3px;
    width: 100%;
    margin: 9px 0px;
}

.links {
    margin-bottom: 14px;
}

.btn {
  background-color: white;
  border: 1px solid $gray-light;
  //border-radius: 1rem;
  color: $gray-dark;
  padding: 0.5rem;
  text-transform: uppercase;
  position:absolute;
    bottom: 12px;

    /* margin: 0px 18px; */
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: -113px;
    text-align: center;
  
    border-top: solid 1px #f5ecdb;
    border-left: solid 1.3px #faf0dc;
    border-right: solid 1px #7d7d7d;
    /* background-color: white; */
    border-bottom: solid 1px #7d7d7d;
      color: white;
    font-weight: 700;
    cursor: pointer;
  
  background: #65D893;
  &.sold-out{
    background:#acacac
}
  
}
// .btn.sold-out{
//     background:#d5d5d5
// }

.btn--block {
  display: block;
  --width: 100%;
}

.card__product-image{
  
  
  
   width: 30%;
    background: pink;
  background: var(--backgroundImage);
  background-position:center;
  background-size:cover;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: -55px;
    position: relative;
    border: white solid 4px;
    margin-bottom: -36px;
  top: -66px;

  
  left: -90px;
  
    &::before {
    content: "";
	  display: block;
    padding-top: (1 / 1) * 100%; // 16:9 aspect ratio
  }
}
 
.cards {
 // max-width:1080px;
  margin:auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;

 // margin: 0;
  //padding: 0;
}

.cards__item {
  display: flex;
  padding: 1rem;
  flex-basis: 360px;
  max-width: 365px;
  // @media(min-width: 40rem) {
  //   width: 50%;
  // }
  // @media(min-width: 56rem) {
  //   width: 33.3333%;
  // }
}

.card {
  background-color: white;
  flex-basis: 360px;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0,0,0,0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &:hover {
    .card__video {
      filter: contrast(100%);
    }
  }
}

.card__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
  position: relative;
}

.card__video{
  filter: contrast(70%);
  transition: filter 0.5s cubic-bezier(.43,.41,.22,.91);
}

  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }

.card__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background:#101228;

  //filter: saturate(180%);
  overflow: hidden;
  position: relative;

  &.loading{
    background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, .5) 50%,
      rgba(255, 255, 255, 0) 60%
    ) var(pink);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
  }
  
  &::before {
    content: "";
	  display: block;
    padding-top: (16 / 9) * 100%; // 16:9 aspect ratio
  }
}

.wrapper {
    margin-bottom: 100px;
}

.card__image--flowers {
  background-image: url(https://unsplash.it/800/600?image=82);
}

.card__image--river {
  background-image: url(https://unsplash.it/800/600?image=11);
}

.card__image--record {
  background-image: url(https://unsplash.it/800/600?image=39);
}

.card__image--fence {
  background-image: url(https://unsplash.it/800/600?image=59);
}
.load{

}

.card__title-row{
    display: flex;
    justify-content: space-between;
  font-size: 1.25rem;
      color: black;
}

.card__title {
  color: $gray-dark;
  
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  
  flex: 1 1 auto;
  //font-size: 0.875rem;
  // line-height: 1.5;
  // margin-bottom: 1.25rem;
  
  min-height: 39px;
  display: flex;
  align-items: center;
  
}

.card__price {
  align-self: center;
  margin-left: 2px;
}

.card__text {
  flex: 1 1 auto;
  margin-top: 12px;
  margin-bottom: 10px;
  
  //font-size: 0.875rem;
  line-height: 1.5;
  //margin-bottom: 1.25rem;
      //align-self: center;
}

.card__rrp{
  color:red;
  text-decoration: line-through;
  font-size: 0.875rem;
}

html{

  
  background: url("https://styrkin.s3-eu-west-1.amazonaws.com/public/site-assets/abstract-svg-shapes/5d65445f9f59d937e3e33735_shape-2.svg"),
    url("https://styrkin.s3-eu-west-1.amazonaws.com/public/site-assets/abstract-svg-shapes/5d6543cc41869b67f7091632_shape.svg"),
    linear-gradient(-59deg, #18171a 17%, #130c23);
  background-size: cover, contain, contain, auto;
  background-size: cover;
  background-repeat: no-repeat, repeat;
}

.cover-photo{
    width: 100%;
    height: 40vh;
    top: 0px;
    background-image: url(https://i.etsystatic.com/isbl/3b75a0/19371102/isbl_1200x300.19371102_p0hpj4tn.jpg?version=0);
    background-size: cover;
    background-position: center;
    margin-bottom: -17vh; 
    display: flex;
    position: absolute;
    z-index: -1;
    border-bottom: solid 1px #fafad27d;
    height: 400px;
}

.card__video{
  position: absolute;
  top: 0px;
  width:100%;
}

.card__top-info{
  position: absolute;
  top: 12px;
  right:12px;
  background:white;
  --width:100%;
  background: #ffffffc2;
  color: #1b1b21;
  padding:5px;
}

.title {
background: #ffffffa6;
    text-align: center;
    /* display: block; */
    border: solid 1px #00000021;
    backdrop-filter: blur(10px);
    margin: 50px auto;
    height: fit-content;
    width: fit-content;
    color: #1b1b21;
    padding: 4px 20px;
    margin-bottom: 60px;
}

.card__checkout-stats{   
    background: #ffe67a;
    display: flex;
    padding: 4px 12px;
    align-items: center;
    margin-top: 12px;
    border-left: 4px solid #FF24E6;
    color: #484747;
  .expiary{
    //font-weight:500;
  }
   i{
     margin-right:6px;
     margin-right: 10px;
     font-size: 21px;
  }
}
.shipping-details{
  color: #484747;
}

table.pricing.tg {
  color: #484747;
  width: 100%;
  margin-top: 7px; 
}
.pricing tr {
   background: #d2d1db; 
}
.pricing td {
    padding: 4px 7px; 
}

.shop-info{
  background: #1f1d24;
  color: #474749;
  -webkit-box-shadow: inset 0px 21px 15px -15px #000000a3;
  box-shadow: inset 0px 21px 15px -15px #000000a3;
}

.about-image{
  margin: 0px auto;
  background:url('https://unsplash.it/1000/1000?image=80');
  height: 275px;
  width:100%;
  max-width:400px;
  background-position:center;
  background-size:cover;
  
}
.about-info{
  height: 500px;
  width:100%;
  max-width:700px;
  margin: 25px auto;
  font-size:25px;
  padding: 0px 9px;

}

///////////////////////

.shop-info{
  //   * {
  //    transition: .33s ease all;
  //    box-sizing: border-box;
  // }

  html {
     font-family: "Helvetica Neue", "Arial";
     box-sizing: border-box;
  }

  span {
     display: inline-block;
     width: 50px;
     height: 5px;
     background-color: #2D58C4;
     margin-bottom: 30px;
  }

  img {
     width: 100%;
  }

  h1 {
     font-weight: 600;
     font-size: 36px;
     margin: 0 0 15px;
  }

  p {
     font-weight: 400;
     line-height: 24px;
     margin: 0 0 15px 0;
  }

  a {
     text-decoration: none;
     text-transform: uppercase;
     color: #2D58C4;
     font-weight: bold;
     font-size: 12px;
     position: relative;
     display: inline-block;
     z-index: 1;
     padding: 5px;
     left: -5px;
  }

  a:hover {
     color: #2D58C4;
  }

  div.site {
     background-color: #f2f2f2;
     min-height: 100vh;
     display: flex;
     align-items: center;
     justify-content: center;
     background-image: linear-gradient(210deg, #1b1b21, #474749);
  }


  div.container {
     background-color: white;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     margin: 10px;
     max-width:450px;
     box-shadow: 0 8px 16px rgba(black, .45);
  }

  div.img {
     height: 300px;
     width: 100%;
     background: url("https://i.etsystatic.com/ipsi/6761b1/1899428135/ipsi_fullxfull.1899428135_sa140ivu.jpg?version=0");
     background-position: center;
     background-size: cover;
  }

  div.text-container {
     margin: 60px 45px 55px;
  }


  @media screen and (min-width: 1024px) {
    div.container {
      // flex-direction: row;
      //  width: 100%;
    }

     div.img {
        height: 420px;
        min-width: 360px;
     }

     div.text-container {
        margin: 0 60px;
        max-width: 660px;
        padding: 18px 0px;
     }
  }

  @media screen and (min-width: 1024px) {
     div.container {
         max-width: 790px;
         flex-direction: row;
        width: 100%;
        position: absolute;

     }
  }

  @media screen and (min-width: 1200px) {
  }

  .social-bar{
     display: flex;
     flex-direction: row;
  }

  .social {
      font-size: 21px;
      width: 35px;
      height: 35px;
      margin: 0px 7px;
      display: flex;
      border: black solid 1px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
  }


}

.card{
  position: relative;
}

.mute-button {
  position: absolute;
  background: #69696987;
  cursor: pointer;
  color: #fafafa;
  z-index: 2;
  padding: 6px 12px;
  border-radius: 9px;
  right: 5px;
  top: 5px;
  border-bottom: solid 1px #2f4f4f78;
  border-top: solid 1px #ffffff61;
  
}

.cards__item{
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.card__text{
  white-space: pre-wrap;
}







</style>

<script context="module">
	let current;
</script>

<script>

 (function(e,g){"object"===typeof exports&&"undefined"!==typeof module?module.exports=g():"function"===typeof define&&define.amd?define(g):(e=e||self,e.currency=g())})(this,function(){function e(b,a){if(!(this instanceof e))return new e(b,a);a=Object.assign({},m,a);var d=Math.pow(10,a.precision);this.intValue=b=g(b,a);this.value=b/d;a.increment=a.increment||1/d;a.groups=a.useVedic?n:p;this.s=a;this.p=d}function g(b,a){var d=2<arguments.length&&void 0!==arguments[2]?arguments[2]:!0;var c=a.decimal;
var h=a.errorOnInvalid,k=a.fromCents,l=Math.pow(10,a.precision),f=b instanceof e;if(f&&k)return b.intValue;if("number"===typeof b||f)c=f?b.value:b;else if("string"===typeof b)h=new RegExp("[^-\\d"+c+"]","g"),c=new RegExp("\\"+c,"g"),c=(c=b.replace(/\((.*)\)/,"-$1").replace(h,"").replace(c,"."))||0;else{if(h)throw Error("Invalid Input");c=0}k||(c=(c*l).toFixed(4));return d?Math.round(c):c}var m={symbol:"$",separator:",",decimal:".",errorOnInvalid:!1,precision:2,pattern:"!#",negativePattern:"-!#",format:function(b,
a){var d=a.pattern,c=a.negativePattern,h=a.symbol,k=a.separator,l=a.decimal;a=a.groups;var f=(""+b).replace(/^-/,"").split("."),q=f[0];f=f[1];return(0<=b.value?d:c).replace("!",h).replace("#",q.replace(a,"$1"+k)+(f?l+f:""))},fromCents:!1},p=/(\d)(?=(\d{3})+\b)/g,n=/(\d)(?=(\d\d)+\d\b)/g;e.prototype={add:function(b){var a=this.s,d=this.p;return e((this.intValue+g(b,a))/(a.fromCents?1:d),a)},subtract:function(b){var a=this.s,d=this.p;return e((this.intValue-g(b,a))/(a.fromCents?1:d),a)},multiply:function(b){var a=
this.s;return e(this.intValue*b/(a.fromCents?1:Math.pow(10,a.precision)),a)},divide:function(b){var a=this.s;return e(this.intValue/g(b,a,!1),a)},distribute:function(b){var a=this.intValue,d=this.p,c=this.s,h=[],k=Math[0<=a?"floor":"ceil"](a/b),l=Math.abs(a-k*b);for(d=c.fromCents?1:d;0!==b;b--){var f=e(k/d,c);0<l--&&(f=f[0<=a?"add":"subtract"](1/d));h.push(f)}return h},dollars:function(){return~~this.value},cents:function(){return~~(this.intValue%this.p)},format:function(b){var a=this.s;return"function"===
typeof b?b(this,a):a.format(this,Object.assign({},a,b))},toString:function(){var b=this.s,a=b.increment;return(Math.round(this.intValue/this.p/a)*a).toFixed(b.precision)},toJSON:function(){return this.value}};return e});


export let product;
export let childProducts;
export let priceFormat = 'int'




const backendUrl = '__backendUrl__';

let video
let muted = true;
let duration;


	function muteOthers() {
    muted = !muted; //toggle
    if (current && current !== video){ current.muted = true}
		current = video;
	}

let activeChildIndex = 0

  const setActiveChild = (childId) => {
      activeChildIndex = product.ParentListing.ChildProducts.findIndex((el)=> el.id === childId )
  }

    product.activeChildProduct = product.ParentListing.ChildProducts[activeChildIndex]

    let activeChildProduct = product.ParentListing.ChildProducts[activeChildIndex]


        const convertNumericToCashVal = (num)=>{
        if( num === null  || num === ''){
            return 'Not Available'
        }

        if( priceFormat === 'decimal' ){
            num = currency(num).intValue
        }
        return parseInt(num, 10) > 1 ? Dinero({ amount: parseInt(num, 10), currency: 'GBP' }).toFormat('$0,0.00') : 'Free'
        }

        const buyItem  = async(event) => {



        const childId = event.target.getAttribute('data-child-id')
        let orderDetails = null

		const res = await fetch( backendUrl +'/public/activeChildProduct/reserve/' + childId, {
              "headers": {
                "accept": "*/*",
                'Content-Type': 'application/json',
                "cache-control": "no-cache",
                "pragma": "no-cache",
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "same-site"
            },
            "method": 'POST',
            "body": JSON.stringify({"quantity": 1}),
        })

        const json = await res.json()
        window.location.href = "/buy-item?order=" + json.id + '&child=' + childId + '&microshop=true';
    }

    const sortChildProductOrder = ()=>{

        let productCard = product.ParentListing

        const sortByArray = ['A5','A5','A4','A3','A2','A1','UK size 6', 'UK size 8', 'UK size 10', 'UK size 12', 'UK size 14', 'UK size 16','XXS','XS','S','M','L','XL','XXL','3XL']
        if(productCard.ChildProducts && Array.isArray(productCard.ChildProducts)){
            if(productCard.ChildProducts[0] && productCard.ChildProducts[0].currentLiveStats){
                productCard.ChildProducts = productCard.ChildProducts.sort(function(a, b){
                    if (productCard.ChildProducts[0].currentLiveStats.stock  < 1){
                        return -99
                    }
                    return sortByArray.findIndex((str)=> str.includes(a.childVariable)) - sortByArray.findIndex((str)=> str.includes(b.childVariable))//sortByArray.indexOf(b.childVariable);
                });
            } else{
                productCard.ChildProducts = productCard.ChildProducts.sort(function(a, b){
                    return sortByArray.findIndex((str)=> str.includes(a.childVariable)) - sortByArray.findIndex((str)=> str.includes(b.childVariable))//sortByArray.indexOf(b.childVariable);
                });
            }

        }
    }

    $:product.ParentListing,sortChildProductOrder()


</script>

    <li class="cards__item">
      <div class="card">

        <div on:click={muteOthers} class='mute-button'>
          {#if muted}
            <i class="fas fa-volume-off"></i> Sound 
          {:else}
            <i class="fas fa-volume-mute"></i>
          {/if}
        </div>

        <div class="card__image {duration?'':'loading'}">
          <video class="lozad card__video" bind:duration src={product.ParentListing.videos && product.ParentListing.videos[0] || 'https://strykin-client-uploads.s3.eu-west-1.amazonaws.com/noID/2256220-1626359111187.mp4'} bind:muted bind:this={video} on:click={muteOthers} playsinline={true}  autoplay={true} loop={true} data-loaded={true}>
           
          </video>
          <!--         <div class='card__top-info'>Only 2 Remaining</div> -->
          {#if product.ParentListing.ChildProducts && product.ParentListing.ChildProducts[activeChildIndex].currentLiveStats && product.ParentListing.ChildProducts[activeChildIndex].currentLiveStats.stock > 0}
            <button class="btn btn--block card__btn" data-child-id={product.ParentListing.ChildProducts[activeChildIndex].id} on:click={buyItem}>Buy now</button>
          {:else}
            <button class="btn btn--block card__btn sold-out" >Sold out</button>
          {/if}
        </div>
        <div class="card__content">
          <div class='card__product-image' style="--backgroundImage:url({product.ParentListing.images[0]})" ></div>
          <div class="card__title-row">
            <div class="card__title">{product.ParentListing.name}</div>
            


            <div class="card__price">
            {#if product.ParentListing.ChildProducts[activeChildIndex].rrp }
              <div class='card__rrp'>{convertNumericToCashVal(product.ParentListing.ChildProducts[activeChildIndex].rrp)}</div>
            {/if}
            {#if product.ParentListing.ChildProducts[activeChildIndex].livePrice }
            {convertNumericToCashVal(product.ParentListing.ChildProducts[activeChildIndex].livePrice)}
            {/if}
            </div>
          </div>
          <!-- {#if product.ParentListing.ChildProducts[activeChildIndex].currentLiveStats && product.ParentListing.ChildProducts[activeChildIndex].currentLiveStats.stock < 5}
          <div class="card__checkout-stats">
              <i class="fas fa-shopping-basket"></i>
              <div class="stats-details"> -->
                <!-- <div class='expiary'> Deal expires in 2 days</div>  -->
                <!-- {#if product.ParentListing.ChildProducts[activeChildIndex].currentLiveStats && product.ParentListing.ChildProducts[activeChildIndex].currentLiveStats.stock > 0}

                  <div class="number-in-stock">Only <span class="value">{product.ParentListing.ChildProducts[activeChildIndex].currentLiveStats.stock}</span> remaining</div>
                  {:else}
                  <div class="number-in-stock">Sold out</div>

                  {/if}

              </div>
            </div>
          {/if} -->

          <div class="card__text">{product.ParentListing.description}</div>
          <!-- <div>reserved:{product.ParentListing.ChildProducts[activeChildIndex].currentLiveStats.incart}</div>
          <div>sold:{product.ParentListing.ChildProducts[activeChildIndex].currentLiveStats.sold}</div>
          <div>stock:{product.ParentListing.ChildProducts[activeChildIndex].currentLiveStats.stock}</div> -->

            <div class="child-variable-switch">
                {#if product.ParentListing.ChildProducts[1]}
                <select value={product.ParentListing.ChildProducts[activeChildIndex].id} on:change="{ (el)=>{
                    setActiveChild(el.target.value)
                  //  activeChildIndex = el.target.value
                }  }">
                    {#each product.ParentListing.ChildProducts as child}
                    <option value={child.id}>
                        {child.childVariable}
                    </option>
                    {/each}
                </select>
                {/if}
            </div>

          <div class="shipping-details">
            <table class="pricing tg">
              <tbody>
                <tr>
                <td class="tg-0lax">UK shipping</td>
                <td class="tg-0lax">{convertNumericToCashVal(product.ParentListing.ChildProducts[activeChildIndex].localShippingCos || product.ParentListing.ChildProducts[activeChildIndex].localShippingCost )}</td>
                </tr>
                <tr>
                <td class="tg-0lax">European shipping</td>
                <td class="tg-0lax">{convertNumericToCashVal(product.ParentListing.ChildProducts[activeChildIndex].europeanShipping || product.ParentListing.ChildProducts[activeChildIndex].europeanShippingCost)}</td>
                </tr>
                <tr>
                <td class="tg-0lax">International shipping</td>
                <td class="tg-0lax">{convertNumericToCashVal(product.ParentListing.ChildProducts[activeChildIndex].internationalShi  || product.ParentListing.ChildProducts[activeChildIndex].internationalShippingCost)}</td>
                </tr>
              </tbody>
            </table>

          </div>

        </div>
      </div>
    </li>