<script>
  import NotCurrentlyLiveContent from "./NotCurrentlyLiveContent.svelte";
  //import io from 'sockets.io'
  const backendUrl = '__backendUrl__';
  const liveSwitchBoard = {}

  var videoSocket = io(backendUrl+'/liveSwitchBoard', {
      transports: ['websocket', 'xhr-polling']
  });

  let hide = true;
  const id = '122323123';


  videoSocket.on('liveSwitchBoard', function (msg) {
    if('liveSwitchBoard',msg.isLive){
      hide = true;
    }else{
      hide = false;
    }
  })




</script>

<style>
  main {
    font-family: sans-serif;
    text-align: center;
  }
  .modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .modal__container {
    z-index: 3;
    background-color: #d100ca;
    background-image: linear-gradient(135deg, #602bb6, #dd1173);
    padding: 30px;
    max-width: 800px;
    max-height: 100vh;
    border-radius: 10px;
    overflow-y: auto;
    box-sizing: border-box;
  }

  .modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal__title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25;
    box-sizing: border-box;
  }

  .modal__close {
    background: transparent;
    border: 0;
    font: inherit;
    line-height: normal;
  }

  .modal__content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    line-height: 1.5;
  }
  

  @keyframes mmfadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes mmfadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes mmslideIn {
    from {
      transform: translateY(15%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes mmslideOut {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-10%);
    }
  }

  :global(.micromodal-slide) {
    display: none;
  }

  :global(.micromodal-slide.is-open) {
    display: block;
  }

  :global(.micromodal-slide[aria-hidden="false"] .modal__overlay) {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  :global(.micromodal-slide[aria-hidden="false"] .modal__container) {
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  :global(.micromodal-slide[aria-hidden="true"] .modal__overlay) {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  :global(.micromodal-slide[aria-hidden="true"] .modal__container) {
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  :global(.micromodal-slide .modal__container),
  :global(.micromodal-slide .modal__overlay) {
    will-change: transform;
  }

  .hide {
    display:none;
  }


</style>

<main>
  <div class="{ hide === true ? 'hide' : ''} modal__overlay" tabindex="-1" >
    <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby={`${id}-title`}>

        <NotCurrentlyLiveContent/>

    </div>
  </div>

</main>