<style>
  #modal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: wheat;
    border: #808080a1 1px solid;
    padding: 9px 18px;
    border-radius: 0.5em;
    white-space: pre-line;
  }
</style>
<script>
export let content;
</script>

<div id="modal">
  {content}
</div>