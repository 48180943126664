<svelte:head>
	<!-- elements go here -->
    <script src="https://sdk.amazonaws.com/js/aws-sdk-2.718.0.min.js" on:load={initializeRemarkable}></script>
    <!-- <script src="/js/s3_photoExample.js"></script> -->
    <link rel='stylesheet' href='https://unpkg.com/uppload@2.3.0/dist/uppload.css'>
    <link rel='stylesheet' href='https://unpkg.com/uppload@2.3.0/dist/themes/light.css'>
</svelte:head>


<script>
	import { getEventsAction } from "../utils/utils.js";
    import { current_component } from "svelte/internal";
    import { Uppload, Local, Crop, en } from "uppload";
    // import { addPhoto2 } from "../utils/aws_utils.js";
    const events = getEventsAction(current_component);


export let value;

export let userId;

export let aspectRatio = (30 / 19);
export let maxSize = [800, 600];
export let hiddenInput = false;
export let id;






// function change(...props) {
//     dispatch('change', ...props);
// }
// function blur(...props) {
//     dispatch('blur', ...props);
// }

    const customUploader = (
      file,
      updateProgress
    ) => {
      // Here, we take the file and send it to the server
      return new Promise((resolve, reject) => {
        // Keep updating the progress
        updateProgress(10); // Progress is now at 10%

        addPhoto2([file], userId  || 'noID',`${
          Math.random().toString().replace("0.", "").substr(0, 7)
          }-${new Date().getTime()}.png`)
          .then(
          function(data) {

            value = data.Location;
            resolve(data.Location);
           
          },
          function(err) {
            return alert("There was an error uploading your photo: ", err.message);
          }
        );
        // Send the file as soon as it is uploaded
        
      });
    }
  	const initializeRemarkable = ()=>{

             let script = document.createElement('script');
            script.src = "/js/s3_photoExample.js"
            document.head.append(script);

               script.onload = function() {



      var picture = new Uppload({
        call: `.uppload-button-${id}`,
        bind: `.${id}`,
        lang: en,
        uploader: customUploader,
        maxSize: maxSize
      });

        picture.use([new Local()]);

        // Effects
        picture.use([new  Crop({ aspectRatio: aspectRatio })]);

           };

      }




</script>
<style>
.uppload-image{
    width:280px;
    display: flex;
}
.btn{
    margin: 5px;
    padding: 9px 19px;
    font-size: 16px;
    background: #37D2A7;
    color: white;
    border-radius: 7px;
    border: 1px solid grey;
    padding: 5px 11px;
    font-size: 13px;
    display: inline-block;
}

#image.hiddenInput{
  display: none;
}

</style>
    {#if value}
    <img class="uppload-image {id}" alt="Picture" src="{value}">
    {/if}
    <br>
    <input
      id="{id}"
      class={hiddenInput?'hiddenInput':''}
      image="image"
      use:events
      {value}
    />
    <div class="btn uppload-button  uppload-button-{id}">
      upload image
    </div>