<svelte:options immutable={false}/>
<script>
  import { createForm } from "svelte-forms-lib";
  import * as yup from "yup";
  import ImageUploader from "../miniComponents/ImageUploader.svelte";
  import VideoUploader from "../miniComponents/VideoUploader.svelte";
  
  import ClientNavBar from "../miniComponents/ClientNavBar.svelte";
  import MicroshopProductCard from "../miniComponents/MicroshopProductCard.svelte"
  import ChildVariables from "./atoms/ChildVariables.svelte"
  import {getStrykinAuth} from "../utils/utils.js";


  (function(e,g){"object"===typeof exports&&"undefined"!==typeof module?module.exports=g():"function"===typeof define&&define.amd?define(g):(e=e||self,e.currency=g())})(this,function(){function e(b,a){if(!(this instanceof e))return new e(b,a);a=Object.assign({},m,a);var d=Math.pow(10,a.precision);this.intValue=b=g(b,a);this.value=b/d;a.increment=a.increment||1/d;a.groups=a.useVedic?n:p;this.s=a;this.p=d}function g(b,a){var d=2<arguments.length&&void 0!==arguments[2]?arguments[2]:!0;var c=a.decimal;
var h=a.errorOnInvalid,k=a.fromCents,l=Math.pow(10,a.precision),f=b instanceof e;if(f&&k)return b.intValue;if("number"===typeof b||f)c=f?b.value:b;else if("string"===typeof b)h=new RegExp("[^-\\d"+c+"]","g"),c=new RegExp("\\"+c,"g"),c=(c=b.replace(/\((.*)\)/,"-$1").replace(h,"").replace(c,"."))||0;else{if(h)throw Error("Invalid Input");c=0}k||(c=(c*l).toFixed(4));return d?Math.round(c):c}var m={symbol:"$",separator:",",decimal:".",errorOnInvalid:!1,precision:2,pattern:"!#",negativePattern:"-!#",format:function(b,
a){var d=a.pattern,c=a.negativePattern,h=a.symbol,k=a.separator,l=a.decimal;a=a.groups;var f=(""+b).replace(/^-/,"").split("."),q=f[0];f=f[1];return(0<=b.value?d:c).replace("!",h).replace("#",q.replace(a,"$1"+k)+(f?l+f:""))},fromCents:!1},p=/(\d)(?=(\d{3})+\b)/g,n=/(\d)(?=(\d\d)+\d\b)/g;e.prototype={add:function(b){var a=this.s,d=this.p;return e((this.intValue+g(b,a))/(a.fromCents?1:d),a)},subtract:function(b){var a=this.s,d=this.p;return e((this.intValue-g(b,a))/(a.fromCents?1:d),a)},multiply:function(b){var a=
this.s;return e(this.intValue*b/(a.fromCents?1:Math.pow(10,a.precision)),a)},divide:function(b){var a=this.s;return e(this.intValue/g(b,a,!1),a)},distribute:function(b){var a=this.intValue,d=this.p,c=this.s,h=[],k=Math[0<=a?"floor":"ceil"](a/b),l=Math.abs(a-k*b);for(d=c.fromCents?1:d;0!==b;b--){var f=e(k/d,c);0<l--&&(f=f[0<=a?"add":"subtract"](1/d));h.push(f)}return h},dollars:function(){return~~this.value},cents:function(){return~~(this.intValue%this.p)},format:function(b){var a=this.s;return"function"===
typeof b?b(this,a):a.format(this,Object.assign({},a,b))},toString:function(){var b=this.s,a=b.increment;return(Math.round(this.intValue/this.p/a)*a).toFixed(b.precision)},toJSON:function(){return this.value}};return e});




  //  const id = window.location.pathname.split("/").pop()
  const backendUrl = "__backendUrl__";

  let listOfUsers = [];
  let initalVideoPreviewUrl = 'https://strykin-client-uploads.s3.eu-west-1.amazonaws.com/noID/2256220-1626359111187.mp4'
  let videoPreview = 'https://strykin-client-uploads.s3.eu-west-1.amazonaws.com/noID/2256220-1626359111187.mp4'

  fetch(backendUrl + "/private/users", {
    headers: {
      Authorization: getStrykinAuth(),
      "Content-Type": "application/json"
    }
  })
    .then(response => {
      return response.json();
    })
    .then(({ users, user }) => {
      if (user) {
        listOfUsers = [user];
        $form.UserId = user.id;
      } else {
        listOfUsers = users;
      }
    })
    .catch(err => {
      console.log("Maybe you are not logged in err:", err);
    });

  const { form, errors, state, handleChange, handleSubmit } = createForm({
    initialValues: {
      UserId: "",
      name: "", //.required(),
      description: "",
      image: "https://strykin-client-uploads.s3.eu-west-1.amazonaws.com/noID/0199274-1626358125904.png",
      video: "",
      suplier: "",
      active: false,
      ChildProducts: [
        {
          id:'0',
          childVariable: "Default",
          livePrice: 0,
          Rrp: 0,
          stock: 0,
          localShippingCost: 0,
          internationalShippingCost: 0,
          europeanShippingCost: 0
        }
      ]
    },
    validationSchema: yup.object().shape({
      userId: yup.string(),
      name: yup.string(), //.required(),
      image: yup.string(),
      description: yup.string(),
      suplier: yup.string().nullable(),
      active: yup.boolean(),
      ChildProducts: yup.array().of(
        yup.object().shape({
          childVariable: yup.string(),
          livePrice: yup.string(),
          rrp: yup.string().nullable(),
          stock: yup.string(),
          localShippingCost: yup.string().nullable(),
          internationalShippingCost: yup.string().nullable(),
          europeanShippingCost: yup.string().nullable()
        })
      )
    }),
      validate: values => {
        let errs = {};
        if (values.video === "" && videoPreview != initalVideoPreviewUrl)  {
          errs["video"] = "Picked video not uploaded, hint: make sure the video is the right size and click 'upload video'";
        }
        return errs;
    },
    onSubmit: values => {
      values.images = [values.image];
      values.videos = [values.video];

      

      values.ChildProducts.forEach((element, i) => {
        values.ChildProducts[i].livePrice = currency(element.livePrice).intValue || null;
        values.ChildProducts[i].rrp = values.ChildProducts[i].rrp ? currency(element.rrp).intValue : null;
        values.ChildProducts[i].stock = parseInt(element.stock) || 0;
        values.ChildProducts[i].localShippingCost =
          currency(element.localShippingCost).intValue || 0;
        values.ChildProducts[i].internationalShippingCost =
           values.ChildProducts[i].internationalShippingCost ? currency(element.internationalShippingCost).intValue : null;
        values.ChildProducts[i].europeanShippingCost =
          values.ChildProducts[i].europeanShippingCost ? currency(element.europeanShippingCost).intValue : null;
      });

      values.childProducts = values.ChildProducts;

      console.log("VALUES", values);

      fetch(backendUrl + "/private/product", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          Authorization:   getStrykinAuth()
        },
        body: JSON.stringify(values)
      }).then(() => {
        window.location.href = "/products_new";
      });
    }
  });


        $: $form, console.log('form', $form.description)

//      console.log('form', $form)


  

  const add = () => {
    $form.ChildProducts = $form.ChildProducts.concat({
      id: $form.ChildProducts.length+'',
      childVariable: "",
      livePrice: $form.ChildProducts[$form.ChildProducts.length - 1].livePrice,
      rrp: $form.ChildProducts[$form.ChildProducts.length - 1].rrp,
      stock: 0,
      localShippingCost: $form.ChildProducts[$form.ChildProducts.length - 1].localShippingCost,
      internationalShippingCost: $form.ChildProducts[$form.ChildProducts.length - 1].internationalShippingCost,
      europeanShippingCost: $form.ChildProducts[$form.ChildProducts.length - 1].europeanShippingCost
    });
    $errors.ChildProducts = $errors.ChildProducts.concat({
      childVariable: "",
      livePrice: "",
      rrp: "",
      stock: "",
      localShippingCost: "",
      internationalShippingCost: "",
      europeanShippingCost: ""
    });
  };

  const handleCurrency = (e) =>{
    console.log('handleCurrency',e)
  }

  let ChildProductsX





  // $: $form.ChildProducts.forEach((element,i) => {
  //   let elementZ = element;
  //   elementZ.livePrice = element.livePrice *2


  //   ChildProductsTest[i] = elementZ;
  // });


    //console.log(ChildProductsX)
    //console.log(ChildProductsTest)
  const formatter = (item)=>{

    console.log('item',item)

    const returnedTarget = [...item];

    console.log('returnedTarget',returnedTarget)


    let foop = returnedTarget.map((element)=>{
        element.livePrice = currency(element.livePrice).intValue || null;
        element.rrp = element.rrp ? currency(element.rrp).intValue : null;
        // element.stock = parseInt(element.stock) || 0;
        // element.localShippingCost = currency(element.localShippingCost).intValue || 0;
        // element.internationalShippingCost = element.internationalShippingCost ? currency(element.internationalShippingCost).intValue : null;
        // element.europeanShippingCost = element.europeanShippingCost ? currency(element.europeanShippingCost).intValue : null;

      return element
    })

    return foop;


  }

    const formatter2 = (item)=>{

    const returnedTarget = item.slice();


    //const returnedTarget = [...item];

    //Object.assign()

    console.log('returnedTarget',returnedTarget)


    let foop = returnedTarget.map((element)=>{
        element.livePrice = 3
        element.rrp = 4
        // element.stock = parseInt(element.stock) || 0;
        // element.localShippingCost = currency(element.localShippingCost).intValue || 0;
        // element.internationalShippingCost = element.internationalShippingCost ? currency(element.internationalShippingCost).intValue : null;
        // element.europeanShippingCost = element.europeanShippingCost ? currency(element.europeanShippingCost).intValue : null;

      return element
    })

    return foop;


  }

 // let pooop = formatter($form.ChildProducts);



  


  const remove = i => () => {
    $form.ChildProducts = $form.ChildProducts.filter((u, j) => j !== i);
    $errors.ChildProducts = $errors.ChildProducts.filter((u, j) => j !== i);
  };

  // fetch( backendUrl + '/private/product/' + id , {
  //     method: 'GET', // or 'PUT'
  //     headers: {
  //        'Content-Type': 'application/json',
  //         'Authorization':  'Bearer '+ sessionStorage.getItem('jwt'),
  //         },
  //   }).then(response => response.json())
  //   .then(body =>{
  //     $form = body.parentListing;
  //     $form.image = body.parentListing.images[0];
  //     $form.suplier = body.parentListing.suplier || ''

  //     $form.ChildProducts = body.parentListing.ChildProducts;
  //     body.parentListing.ChildProducts.forEach((el, i)=>{
  //       body.parentListing.ChildProducts[i].rrp = body.parentListing.ChildProducts[i].rrp || ''
  //       body.parentListing.ChildProducts[i].localShippingCost = body.parentListing.ChildProducts[i].localShippingCost || ''
  //       body.parentListing.ChildProducts[i].internationalShippingCost = body.parentListing.ChildProducts[i].internationalShippingCost || ''
  //       body.parentListing.ChildProducts[i].europeanShippingCost = body.parentListing.ChildProducts[i].europeanShippingCost || ''

  //        $errors.ChildProducts = $errors.ChildProducts.concat({
  //             childVariable:'',
  //             livePrice:'',
  //             rrp:'',
  //             stock:'',
  //             localShippingCost:'',
  //             internationalShippingCost:'',
  //             europeanShippingCost:''
  //         });
  //     })

  //   })
</script>

<style>
main{
  display: flex;
  flex-wrap: wrap;
}
aside{
  flex-grow: 1;
}


  .error {
    display: block;
    color: red;
  }
  .form-group {
    display: flex;
    align-items: baseline;
  }
  .button-group {
    display: flex;
  }
  button ~ button {
    margin-left: 15px;
  }

  input.shipping,
  input.stock,
  input.rrp,
  input.live-price {
    width: 90px;
  }

  .form-group:first-of-type label {
    font-size: 17px;
  }

  .form-group label {
    display: inline-block;
    font-size: 0px;
  }

  .form-group > div {
    width: min-content;
  }

  form > label {
    font-size: 17px;
    /* min-width: 19px; */
    display: block;
    margin-top: 5px;
  }

  form {
    font-family: sans-serif;
  }
  input,
  textarea {
    padding: 3px 4px;
  }

  textarea {
    width: 350px;
    height: 80px;
  }

  textarea#description {
    width: 300px;
    height: 118px;
  }
  .submit {
    display: block;
    margin: 22px;
    padding: 9px 19px;
    font-size: 18px;
    background: #37d2a7;
    color: white;
    border-radius: 7px;
    border: 1px solid grey;
  }
  input#name {
    width: 300px;
  }
  main {
    margin: 8px;
  }

  .note {
    display: inline-block;
    border: solid 0.5px #b3b3b3;
    padding: 8px 10px;
    margin: 8px 0px;
    background: #0A75CD;
    border-radius: 5px;
    color: white;
  }
  .note .title{
    display: block;
  }
  .note a{
    color:white;
    font-weight: bold;
  }
  .user-id-wrap-hide.active{
    display:none;
  }
  .zero-stock{
    background: #fff39f;
  }

  


</style>


<ClientNavBar />
<main>
  <form on:submit={handleSubmit}>
    <h1>New Product</h1>

    <span class='user-id-wrap-hide {listOfUsers[1]?'':'active'}'>
      <label for="userId">userId</label>
      <input
        id="userId"
        userId="userId"
        on:change={handleChange}
        on:blur={handleChange}
        bind:value={$form.UserId}
        list="user-list"
        autocomplete="off" />
      <datalist class="user-list" id="user-list">
        {#each listOfUsers as user}
          <option value={user.id}>{user.email}</option>
        {/each}
      </datalist>

      {#if $errors.UserId}
        <small>{$errors.UserId}</small>
      {/if}
      <br />
    </span>


    <label for="name">Product name</label>
    <input
      id="name"
      name="name"
      on:change={handleChange}
      on:blur={handleChange}
      bind:value={$form.name} />
    {#if $errors.name}
      <small>{$errors.name}</small>
    {/if}
    <br />

    <label for="description">Product description: 180 Characters</label>
    <textarea
      id="description"
      name="description"
      on:change={handleChange}
      on:blur={handleChange}
      bind:value={$form.description}
      maxlength="190" />
    {#if $errors.description}
      <small>{$errors.description}</small>
    {/if}
    <br />

    <label for="image">
      Product image
      <ImageUploader
        id="image"
        image="image"
        on:change={handleChange}
        on:blur={handleChange}
        bind:value={$form.image}
        aspectRatio={(1/1)}
        userId={$form.UserId} />
        
    </label>
    {#if $errors.image}
      <small>{$errors.image}</small>
    {/if}
    <br />




    <label for="video">
      Product video
      <VideoUploader
        id="video"
        video="video"
        on:change={handleChange}
        on:blur={handleChange}
        bind:value={$form.video}
        bind:videoPreview={videoPreview}
        userId={$form.UserId} 
      />
    </label>
      {#if $errors.video}
      <div>
        <aside class='note error'>
          <span class='title'> Error: </span>
          {$errors.video}
        </aside>
      </div>

      {/if}
      <aside class='note'>
      <span class='title'> Note: </span>
         Video is optional and only required for microshop products.
         <br>
         Tip: You can download your instagram reels in the right format <a href='https://inflact.com/downloader/'>here</a> 
      </aside>
    <br />



    <!-- <label for="suplier">suplier</label>
    <input
      id="suplier"
      suplier="suplier"
      on:change={handleChange}
      on:blur={handleChange}
      bind:value={$form.suplier}
    />
    {#if $errors.suplier}
      <small>{$errors.suplier}</small>
    {/if}
    <br> -->

    <label for="active">Active (Do you want to show this product in your next live?)</label>
    <input
      id="active"
      active="active"
      bind:checked={$form.active}
      type="checkbox" />
    {#if $errors.active}
      <small>{$errors.active}</small>
    {/if}
    <br />

    <!-- <label for="livePrice">livePrice</label>
    <input
      id="livePrice"
      livePrice="livePrice"
      on:change={handleChange}
      on:blur={handleChange}
      bind:value={$form.livePrice}
    />
    {#if $errors.livePrice}
      <small>{$errors.livePrice}</small>
    {/if}
    <br> -->
    <aside class='note'>
    <span class='title'> Note: </span>
    All prices are in GBP (00.00)
    </aside>

    <br/>

    {#each $form.ChildProducts as childProduct, j}
         <ChildVariables 
        bind:childProduct={$form.ChildProducts[j]}
        bind:errors={$errors.ChildProducts[j]}
        increment={j}
        childProductsLength={$form.ChildProducts.length}
        handleChange={handleChange}
        add={add}
        remove={remove}
       
       ></ChildVariables>
    {/each}

    <button class="submit" type="submit">Make New Product</button>
  </form>
  <aside>
    <MicroshopProductCard product={{
          ChildProducts:$form.ChildProducts,
          ParentListing: {
            ChildProducts:$form.ChildProducts,
            description:$form.description,
            name: $form.name,
            images:[$form.image],
            videos:[videoPreview]
          },
        }} 
        childProducts={$form.ChildProducts}
        priceFormat={'decimal'}
        />


  </aside>
</main>
