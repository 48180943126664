<script>

  let starProds = ['fdc32ea1-0718-4601-904a-eb78667669ec','01553e37-2324-4998-ba80-624aa5257d34'] // unhardcode this

  let spotlightProducts = [
      {
        video:'https://strykin-client-uploads.s3.eu-west-1.amazonaws.com/6c3ffc32-64c6-4bf5-b8df-d72bfaf41820/4043805-1632932566733.mp4',
        userId:'6c3ffc32-64c6-4bf5-b8df-d72bfaf41820',
        link:'http://google.com'
      },
      {
        video:'https://strykin-client-uploads.s3.eu-west-1.amazonaws.com/1729c527-d098-4444-9cd9-c591080a569d/1626586-1633625210786.mp4',
        userId:'1729c527-d098-4444-9cd9-c591080a569d',
        link:'http://google.com'
      },
      {
        video:'https://strykin-client-uploads.s3.eu-west-1.amazonaws.com/8968484c-1ce7-4f50-8015-1d101583af10/9580693-1630588321056.mp4',
        userId:'8968484c-1ce7-4f50-8015-1d101583af10',
        link:'http://google.com'
      },
      {
        video:'https://strykin-client-uploads.s3.eu-west-1.amazonaws.com/0ecae77f-3a2a-41bb-9737-f24a70322204/9048173-1628948518862.mp4',
        userId:'0ecae77f-3a2a-41bb-9737-f24a70322204',
        link:'http://google.com'
      },
      ]
  let muted = true;

  let spotlightProduct = false

  let stockList = []
  const backendUrl = "__backendUrl__";

  import VideoLink from "./miniComponents/atoms/minivideo.svelte";

  

    // fetch(backendUrl + "/public/parentListing/" + starProds[0]+'?withKids=true', {
    //     method: "GET",
    //     headers: {
    //     "Content-Type": "application/json"
    //     }
    // })
    // .then((response)=>{
    //     return response.json();
    // })
    // .then(({parentListing})=>{

    //         console.log(parentListing)
    //         spotlightProduct = {
    //             video:parentListing.videos[0],
    //             userId:parentListing.UserId,
    //         }
    //         console.log('spot',spotlightProduct)

    // })

    const followLink = (link)=>{
      window.location.href = link;
    }

</script>
<svelte:head>
  <!-- elements go here -->

  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" />
</svelte:head>


<style>
  div {
    /* background: #ffc0cb57;
    border: 0.1px solid red; */
  }

  main {
    overflow: hidden;
  }

  .video-link{
    position: absolute;
    /* background: aqua; */
    z-index: 99;
    bottom: 20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    place-items: center;
    place-content: center;
    text-align: center;
    padding: 5px 5px;
    border-radius: 5px;
    background: #65d893cf;
    color: white;
    border: 1px solid #cdedd9cf;
    cursor: pointer;

    font-size: 12px;
    width: 71px;
        box-shadow: 0 4px 5px 0 rgb(0 0 0 / 25%);
}

footer {
    display: flex;
    justify-content: center;
    margin-top: 66px;
    background: #ede7e7;
    border-top: groove 2px;
    padding: 21px 0px;
    font-size: 13px;
}

  .WelcomeBanner_welcome-banner__section h2 {
    /* background-color: #f0f2f5; */
    background: #ffffffa3;
  }
  .WelcomeBanner_welcome-banner__text h2{
    background: #ffffffa3;
  }

  .WelcomeBanner_welcome-banner {
    position: relative;
    overflow: visible;
    height: 250px;
    /* background-color: #f0f2f5; */
    box-shadow: 0 0 0 0.5px #d8d8d8;
  }
  .container {
    margin: 0 auto;
    max-width: 71.25rem;
    padding: 0 2rem;
  }

  .WelcomeBanner_welcome-banner__text {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 450px;
    position: absolute;
    z-index: 1;
    padding: 16px 0;
  }

  .WelcomeBanner_welcome-banner__video-wrapper {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 460px;
    height: 100%;
    overflow: visible;
    position: absolute;
    right: -20px;
  }

  .WelcomeBanner_welcome-banner__button {
    color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 5px 0 rgb(0 0 0 / 25%);
    font-size: 18px;
    line-height: 1;
    margin: 8px;
    padding: 16px 28px;
    position: absolute;
    top: 25px;
    right: 45px;
    z-index: 1;
    display: none;
    background: #65d893;
  }

  .WelcomeBanner_welcome-banner__button.mobile{
    position: initial;
    width: fit-content;
    margin: 0px;
    display: block;
    padding: 12px 18px;
  }

  .WelcomeBanner_welcome-banner__video {
    display: none;
  }

  .WelcomeBanner_welcome-banner__video-mobile,
  .WelcomeBanner_welcome-banner__video {
    height: 100%;
    max-width: unset;
  }
  .WelcomeBanner_welcome-banner__video {
    height: 100%;
    max-width: unset;
  }

  .WelcomeBanner_welcome-banner__video-mobile {
    display: block;
  }

  .SeeMore_SeeMoreRow__LvMck {
    display: grid;
    grid-gap: 1.25rem;
  }

  .SeeMore_SeeMoreRow__LvMck > * {
    width: auto;
  }

  .SeeMore_SeeMoreRow--4__3Khbn {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  .Card_card__P20Zv {
    border-radius: 10px;
    -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
    @keyframes shine {
    to {
        background-position-x: -200%;
    }
    }

  .Card_card__P20Zv{
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }



  @media (min-width: 30.0625em) {
      .video-link{
        font-size: 16px;
        /* height: 30px; */
        width: 85px;
      }

    .WelcomeBanner_welcome-banner__section {
      background-color: #fff;
    }

    .WelcomeBanner_welcome-banner {
      height: 350px;
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      box-shadow: none;
    }
    .WelcomeBanner_welcome-banner__text {
      flex-basis: 510px;
      padding: 50px 35px 80px 0;
      position: static;
      z-index: unset;
    }

    .WelcomeBanner_welcome-banner__video-wrapper {
      position: static;
    }
    .WelcomeBanner_welcome-banner__button {
      display: block;
    }
    .WelcomeBanner_welcome-banner__video {
      display: block;
    }
    .WelcomeBanner_welcome-banner__video-mobile {
      display: none;
    }
    .SeeMore_SeeMoreRow--4__3Khbn {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
          0,
          1fr
        );
    }


    
  }

  

  @media (max-width: 48em) {
    .container {
      padding: 0 0.3rem
    }
  }


  
footer {
  display: flex;
  justify-content: center; 
  margin-top: 66px;
  }
/* h3 {
    margin-top: 0px;
} */

  footer a {
    color: #302499; }
  footer .footer-wrapper {
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-wrap: wrap; }
  footer .about {
    flex: 3;
    min-width: 200px; }
  footer .link-list {
    flex: 1; 
    padding: 19px 26px;
    }

.footer-logo {
  background: url(https://styrkin.s3-eu-west-1.amazonaws.com/public/logo_2.png);
  width: 35px;
  margin-left: 11px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.footer-logo {
  margin-right: 24px; }
  .about{
    padding-right:10px;
  }

</style>

<main>
  <div class="WelcomeBanner_welcome-banner__section">
    <div class="WelcomeBanner_welcome-banner container container--narrow">
      <div class="WelcomeBanner_welcome-banner__text">
        <h2>Welcome to the Strykin hub</h2>
        <p>
          Amazing creators showing off their incredible creations.
        </p>
        <div
            aria-label="Sign up"
            class="WelcomeBanner_welcome-banner__button mobile"
            tabindex="0"
            role="button"
            style="cursor: pointer; outline: none;"
            on:click={()=>{followLink('/signup')}}
            >
            Join in
        </div>
        <!-- <form class="WelcomeBanner_welcome-banner__search">
          <div class="SearchBox_search-form__ZqM-- SearchBox_welcome">
            <input
              class="SearchBox_search-form__input"
              type="text"
              placeholder="Search for Products, Brands and Videos"
              value="" />
            <button type="button" class="SearchBox_search-form__clear__34-4v">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon--close ">
                <path d="M12 .2L.2 12 2 13.8 13.8 2z" />
                <path d="M2 .2L13.8 12 12 13.8.2 2z" />
              </svg>
            </button>
          </div>
        </form> -->
        <div
          aria-label="Sign up"
          class="WelcomeBanner_welcome-banner__button-mobile"
          tabindex="0"
          role="button"
          on:click={()=>{followLink('/signup')}}
          style="cursor: pointer; outline: none;">
          <div aria-label="Sign up" class="WelcomeBanner_welcome-banner__button" tabindex="0" role="button" style="cursor: pointer; outline: none;">Sign up</div>
        </div>
      </div>
      <div class="WelcomeBanner_welcome-banner__video-wrapper">
        <!-- svelte-ignore a11y-media-has-caption -->
        <video
          class="WelcomeBanner_welcome-banner__video"
          preload="metadata"
          playsinline
          loop
          autoplay
          muted
          aria-label="auto play example of supergreat reviews">
          <source
            src="https://styrkin.s3.eu-west-1.amazonaws.com/public/microshophub/hub-web-banner-video_2.mp4"
            type="video/mp4" />
        </video>
        <!-- svelte-ignore a11y-media-has-caption -->
        <video
          class="WelcomeBanner_welcome-banner__video-mobile"
          preload="metadata"
          playsinline
          loop
          autoplay
          muted
          aria-label="auto play example of supergreat reviews">
          <source
            src="https://styrkin.s3.eu-west-1.amazonaws.com/public/microshophub/hub-welcome-video-mobile.mp4"
            type="video/mp4" />
        </video>
      </div>
    </div>
  </div>

  <section>
    <div class="container container--narrow">
      <div>
        <div class="SeeMore_SeeMoreRow__title__20fs-">
          <h3>Microshop Spotlight</h3>
        </div>
        <div class="SeeMore_SeeMoreRow__LvMck SeeMore_SeeMoreRow--4__3Khbn">
          {#if spotlightProducts.length > 0}
          {#each spotlightProducts as spotlightProduct}
          <div class="Card_card__P20Zv undefined">
              <div
                style="display: inline-block; max-width: 100%; overflow: hidden;
                position: relative; box-sizing: border-box; margin: 0px;">
                <div
                  style="box-sizing: border-box; display: block; max-width:
                  100%;">


                </div>
                    
                        <!-- svelte-ignore a11y-media-has-caption -->
                        <VideoLink video={spotlightProduct.video} ></VideoLink>

                        <div class='video-link'>Creator Microshop</div>

                    
              </div>
          </div>
          {/each}
          {/if}
        </div>
      </div>
    </div>

  </section>
  <section>

  </section>
  <footer>
  <div class='footer-wrapper'>
    <div class='footer-logo'></div>
    <div class='about'>
      <h3> Strykin Live</h3>
      Strykin live is in an Ecommerce marketplace, bringing together buyers and sellers through live streams. For further infomation or for partnership opportunities please contact theo@strykin.live
    </div>
    <div class='link-list'>
      <div>Links</div>
      <ul class="footer-links">
        <li><a href="www.strykin.com">Homepage</a></li>
        <li><a href="https://www.strykin.com/stream-with-us">Stream With Us</a></li>
      </ul>
    </div>
  </div>
</footer>

</main>
