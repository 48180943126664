<script>
  import { createForm } from "svelte-forms-lib";
  import * as yup from "yup";
  import ImageUploader from "../miniComponents/ImageUploader.svelte";
  import ClientNavBar from "../miniComponents/ClientNavBar.svelte";
  // import Datepicker from "Datepicker"
  import Datepicker from 'svelte-calendar'
  import PickAnHour from '../miniComponents/PickAnHour.svelte'
  import {getStrykinAuth} from "../utils/utils.js";

  //  const id = window.location.pathname.split("/").pop()
  const backendUrl = "__backendUrl__";
  let cachedDate = null

  let listOfUsers = [];

  let bookedDates = [];
  const id = window.location.pathname.split("/").pop()

    let agreedTerms = false;

  
  let noWeekendsSelectableCallback = (date) =>{ 
    //console.log(date.toDateString())

    //console.log(bookedDates[0].toDateString())

    if(bookedDates[0]){

      if(bookedDates.find((x)=>{return x.toDateString() === date.toDateString()})){
        console.count()
        console.log('hi🤑t')
        return false 
      }



      if(bookedDates[0].toDateString() === date.toDateString()){
        //console.log('hi🤑t')
        console.log('hit')

      }
    }



    return true;
  }

  fetch(backendUrl + "/public/stream?time=future", {
    headers: {
      Authorization: getStrykinAuth(),
      "Content-Type": "application/json"
    }
  })
  .then(response => {
    return response.json();
  })
  .then((bookedStreams) => {
    bookedDates = Object.values(bookedStreams).map((stream)=>{
      return new Date(stream.startTime);
    })
    noWeekendsSelectableCallback = noWeekendsSelectableCallback
    console.log('booked dates',bookedDates);
  })
  .catch(err => {
    bookedDates = [];
    console.log("Maybe you are not logged in err:", err);
  });



  fetch(backendUrl + "/private/users", {
    headers: {
      Authorization: getStrykinAuth(),
      "Content-Type": "application/json"
    }
  })
  .then(response => {
    return response.json();
  })
  .then(({ users, user }) => {
    if (user) {
      listOfUsers = [user];
    } else {
      listOfUsers = users;
    }
  })
  .catch(err => {
    listOfUsers = [];
    console.log("Maybe you are not logged in err:", err);
  });

  



  const { form, errors, state, handleChange, handleSubmit } = createForm({
    initialValues: {
      UserId: "",
      name: "", //.required(),
      description: "",
      image: "",
      notes: "",
      facebookStreamKey:"",
      active: false,
    },
    validationSchema: yup.object().shape({
      userId: yup.string(),
      name: yup.string(), //.required(),
      image: yup.string(),
      facebookStreamKey: yup.string(),
      description: yup.string(),
      notes: yup.string(),
    }),
    onSubmit: values => {
      console.log("Vll", values);

      

      values.selectedDate.setUTCHours(values.selectedHour.split(':')[0])

      values.startTime = values.selectedDate.getTime();

     // console.log('VALS',values)

      fetch(backendUrl + "/private/stream/"+ id , {
        method: "PUT", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          Authorization: getStrykinAuth()
        },
        body: JSON.stringify(values)
      }).then(() => {
        window.location.href = "/streamList";
      }).catch((err)=>{
        console.log(err)
      })
    }
  });

  
  fetch( backendUrl + '/private/stream/' + id , {
    method: 'GET', // or 'PUT'
    headers: {
        'Content-Type': 'application/json',
        'Authorization': getStrykinAuth(),
        },
  })
  .then(response => response.json())
  .then(body =>{
    //console.log('b🤠ody',body)
    $form.selectedDate = new Date(body.startTime)
    $form.description = body.description;
    $form.UserId =  body.UserId;
    $form.name =  body.name;
    $form.image =  body.image;
    $form.privateStreamDetails = body.privateStreamDetails;
    $form.selectedHour = new Date(body.startTime).getHours() + ':00:00'

    bookedDates = bookedDates.filter(date => date.toDateString() !== new Date(body.startTime).toDateString() );
    noWeekendsSelectableCallback = noWeekendsSelectableCallback;

  })


  let listOfUsersX = [];

  let count = 0



  let fodsf = () =>{


    if( cachedDate != $form.selectedDate){
      cachedDate = $form.selectedDate;


      const tsdfs = $form.selectedDate;

      const queryDate = tsdfs.getFullYear() +'-'+ (tsdfs.getMonth()+ 1) +'-'+tsdfs.getDate()

      fetch(backendUrl + "/private/stream/on/" + queryDate, {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          Authorization: getStrykinAuth()
        }
      }).then(response => {
        return response.json();
      })
      .then((mjklj) => {
        //window.location.href = "/products_new";
        if(mjklj[0]){
          //listOfUsersX = mjklj.map((x)=>x.startTime.split('T')[1].split('.')[0]);
           listOfUsersX = mjklj.map((x)=>x.startTime);
        }else{
          listOfUsersX=[];
        }


         
        console.log("VZZZZALUEZ2", listOfUsersX);
      })
    }
    count = count + 1;

  }




  //$: $form.selectedDate , fodsf()

  //date format
  let dateFormat = '#{l}, #{F} #{j}, #{Y}';

  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  let userType = sessionStorage.getItem('userType');





</script>

<style>
  .error {
    display: block;
    color: red;
  }
  .form-group {
    display: flex;
    align-items: baseline;
  }
  .button-group {
    display: flex;
  }
  button ~ button {
    margin-left: 15px;
  }

  input.shipping,
  input.stock,
  input.rrp,
  input.live-price {
    width: 90px;
  }

  .form-group:first-of-type label {
    font-size: 17px;
  }

  .form-group label {
    display: inline-block;
    font-size: 0px;
  }

  .form-group > div {
    width: min-content;
  }

  form > label {
    font-size: 17px;
    font-size: 17px;
    margin-top: 24px;
    /* min-width: 19px; */
    display: block;
  }

  form {
    font-family: sans-serif;
  }
  input,
  textarea {
    padding: 3px 4px;
  }

  textarea {
    width: 350px;
    height: 80px;
  }

  textarea#description {
    width: 300px;
    height: 118px;
  }
  .submit {
    display: block;
    margin: 22px;
    padding: 9px 19px;
    font-size: 18px;
    background: #37d2a7;
    color: white;
    border-radius: 7px;
    border: 1px solid grey;
  }
  input#name {
    width: 300px;
  }
  main {
    /* margin: 8px; */
    max-width: 900px;
    margin: 17px auto;
    padding: 0px 18px;
  }
  .user-id-wrap-hide.active{
    display:none;
  }
  button:disabled,
  button[disabled]{
    background-color: #cecece;
    color: #504f4f;
  }
  .submision-note {
    background: #d6efdb;
    padding: 13px;
    margin: 12px 0px;
    border-radius: 18px;
    border: grey solid 0.2px;
  }

  
  .full-width-background{
    position: relative;
    background: #e8e8e8;
    padding: 13px 2px 13px 2px;
    margin-top: 16px;
    display: grid;
  }

  .full-width-background:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -9999px;
    right: 0;
    border-left: 10000px solid #e8e8e8;
    box-shadow: 9999px 0 0 #e8e8e8;
    z-index: -1;
  }

  .submisison-confirmation {
    display: flex;
  }

  input#confirm {
    margin-right: 20px;
  }

  span.sub-label {
    font-size: 0.84em;
  }

</style>


<ClientNavBar />
<main>
  <form on:submit={handleSubmit}>
    <h1>Edit Booking</h1>

    <span class='user-id-wrap-hide {userType=='admin'?'':'active'}'>
      <label for="userId">userId</label>
      <input
        id="userId"
        userId="userId"
        on:change={handleChange}
        on:blur={handleChange}
        bind:value={$form.UserId}
        list="user-list"
        autocomplete="off" />
      <datalist class="user-list" id="user-list">
        {#each listOfUsers as user}
          <option value={user.id}>{user.email}</option>
        {/each}
      </datalist>          


      {#if $errors.UserId}
        <small>{$errors.UserId}</small>
      {/if}
      <br />
    </span>

    <label for="name">Stream name</label>
    <input
      id="name"
      name="name"
      on:change={handleChange}
      on:blur={handleChange}
      bind:value={$form.name} />
    {#if $errors.name}
      <small>{$errors.name}</small>
    {/if}
    <br />

    <label for="description">Stream Description: <span class='sub-label'>(180 Characters)</span></label>
    <textarea
      id="description"
      name="description"
      on:change={handleChange}
      on:blur={handleChange}
      bind:value={$form.description}
      maxlength="300" />
    {#if $errors.description}
      <small>{$errors.description}</small>
    {/if}
    <br />

    <div class='stream-time full-width-background' >
      <label>Stream Date</label>
      <div>
        <Datepicker
          format={dateFormat}
          bind:formattedSelected={$form.selectedDateFormatted}
          bind:selected={$form.selectedDate}
          start={ tomorrow }
          selectableCallback={noWeekendsSelectableCallback}
          
        />
      </div>


      <br />
      <br />

      <PickAnHour bind:timesTaken={listOfUsersX} bind:value={$form.selectedHour}/>

    </div>


    <label for="image">
      Stream Cover Image <span class='sub-label'>(500px x 500px)</span>
      <ImageUploader
        id="image"
        image="image"
        aspectRatio=1
        on:change={handleChange}
        on:blur={handleChange}
        bind:value={$form.image}
        userId={$form.UserId} />
    </label>
    {#if $errors.image}
      <small>{$errors.image}</small>
    {/if}

    <!-- <label for="facebookStreamKey">facebook Stream Key</label>
    <input
      id="facebookStreamKey"
      name="facebookStreamKey"
      on:change={handleChange}
      on:blur={handleChange}
      bind:value={$form.facebookStreamKey} />
    {#if $errors.facebookStreamKey}
      <small>{$errors.facebookStreamKey}</small>
    {/if}
    <br /> -->


    <div class="full-width-background">
      <label for="notes">Notes (only visible to Strykin editors)</label>
      <textarea
        id="notes"
        name="notes"
        on:change={handleChange}
        on:blur={handleChange}
        bind:value={$form.notes}
        maxlength="190" />
      {#if $errors.notes}
        <small>{$errors.notes}</small>
      {/if}
    </div>
    <br />


    <div class='submision-note'>
      <h3>Please read first</h3>
        <label for="confirm">
        <div class='submisison-confirmation'>
            <input  id="confirm" name="confirm" type=checkbox bind:checked={agreedTerms}>
            <div>
            I understand that the changes I made to my booking will be visible in Strykin’s public calendar and I confirm that I will go live at the agreed time.
            </div>
          </div>
        </label>
        <button  disabled={!agreedTerms} class="submit" type="submit">Make Booking</button>
    </div>

  </form>
</main>
