<style>
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Karla&display=swap");
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0px;
  text-align: center;
  font-family: "Karla", sans-serif;
}

h1{
    padding: 20px;
    margin: 0px;
}

h1,
h2 {
  font-family: "Bebas Neue", cursive;
}

.not-live-modal{
    background: #c4a3bd70;
    color:#fffbf2;
    border-radius: 6px;
}


.modal-content {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 7px;
}

.main {
  display: inline-flex;
  flex: 1;
  backdrop-filter: blur(5px);
  flex-basis: 340px;
  padding: 0px 9px;
}

.event {
  font-family: "Karla", sans-serif;
  box-sizing: inherit;
  color: white;
  min-height: auto;
  --width: 340px;
  margin: 4px;
  display: flex;
  background: #0dc0ff99;
}

.event .info .title{
    font-size: 1.2em;
}

.description {
  font-size: 12px;
}

.image {
  font-family: "Karla", sans-serif;
  color: white;
  box-sizing: inherit;
  /* height: 95px;
  min-width: 95px; */
  background: url(https://styrkin.s3-eu-west-1.amazonaws.com/public/calendar_images/thisisjohnturner.jpg?v=2020-05-13T19:00:00);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: white;
  background-position: center;
}

.info {
  padding: 6px 4px;
  text-align: left;
}

.links {
  display: flex;
  justify-content: space-around;
}
.links .link {
  color: white;
  text-align: center;
  margin: 11px 8px;
  background: #8855a78a;
  padding: 6px;
  border-radius: 11px;
  cursor: pointer;
  width: 79px;
  display: inline-grid;
  border: 1px #ffffff59 solid;
  margin: 8px 0px;
  white-space: normal;
}

.link:hover{
    background: #884890;
}

.links .link i {
  font-size: 45px;
  padding: 5px 0px;
}

.about-us .description {
  padding: 6px;
  font-size: 16px;
}

</style>

<div class='not-live-modal'>
  <h1>Next stream is coming soon</h1>

  <div class='modal-content'>
    <div class='main main-1'>
      <div class='about-us'>
        <h2>What we do</h2>
        <div class='description'>
          Strykin Live is a novel e-commerce platform where businesses can achieve real time sales from live streamed content. With authenticity and engagement at it's core, Strykin Live is the closest digital experience to a real life consumer interaction.
        </div>
        <div class='links'>
          <div class='link'>
            <i class="fas fa-play"></i>
            <div class='icon-text'>Stream with us</div>
          </div>
          <div class='link'>
            <i class="fas fa-bolt"></i>
            <div class='icon-text'>About us</div>
          </div>
        </div>
      </div>
    </div>
    <div class='main main-2'>
      <div class='calendar'>
        <h2>Coming up next</h2>
        <div class='upcoming-events'>
          <div class="schedule">

            <div class="d13052020">
              <div class="date">Wednesday 13th May</div>

              <div class="event craft">
                <div class="image" style="background:url(https://styrkin.s3-eu-west-1.amazonaws.com/public/calendar_images/thisisjohnturner.jpg?v=2020-05-13T19:00:00); background-size:cover; background-repeat: no-repeat; background-position: center;"></div>
                <div class="info">
                  <div class="title">
                    This Is Jon Turner (Artist)
                  </div>
                  <div class="times">
                    7:00pm -
                    9:00pm (BST)
                  </div>
                  <div class="description">
                    Forged in the fires of a distant moon and forced to wander a lonely path through the infinite, desolate vacuum of space.
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>