dsfsdf
<Player
  src="https://londonweb.dev:5443/LiveApp/streams/208637388221907146499908.m3u8"
  {providers}
  bind:this={player} 
  liveStreamIndicator=true
/>
<!-- <Player
  src="youtube/zBYX6JjOCuc"
  {providers}
  bind:this={player} 
/> -->
csd
<script>
  import { onMount } from 'svelte';
  // All providers are named {ProviderName}Provider.
  import { Player, FileProvider, HlsProvider, YouTubeProvider } from '@vime-js/standard';

  let player;

  const providers = [HlsProvider];

  onMount(() => {
    /**
     * If you need to call any methods, you have access 
     * to the player instance here.
     **/
  });
</script>