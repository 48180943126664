<script>
var colours = [
    '#EF5350',
    '#E91E63',
    '#BA68C8',
    '#9575CD',
    '#7986CB',
    '#1E88E5',
    '#40C4FF',
    '#4DD0E1',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFEE58',
    '#FFC107',
    '#FB8C00',
    '#FFA726',
    '#FF8A65',
    '#6D4C41',
    '#757575',
    '#607D8B'
]

export let activeValue;

let switchActive = (colour)=>{
    activeValue = colour
}

</script>

<style>
.selected{
    border: solid 3px;
    border-color: #eaeaea;
}
.quick-colours {
    display: flex;
    flex-wrap: wrap;
    width: 280px;
}
.colour-button{
    box-sizing: border-box;
    height:20px;
    width:20px;
    margin:4px;
    border-radius: 100%;
    cursor: pointer;
}
</style>

<div class='quick-colours' >
    {#each colours as colour}
       <div  
       class:selected="{activeValue === colour}" 
       class='colour-button' 
       on:click={()=>{switchActive(colour)}}
       style="background: {colour}"
       ></div>
    {/each}

</div>