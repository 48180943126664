import App from './App.svelte';
import AppLiveList from './AppLiveList.svelte';
import StrykinSpotlight from './StrykinSpotlight.svelte';
import NotCurrentlyOnlinePage from './NotCurrentlyOnlinePage.svelte';
import LiveProductButton from './LiveProductButton.svelte';
import AdminDashboard from './AdminDashboard.svelte';
import ComingSoon from './miniComponents/ComingSoon.svelte';
import ProductListForEditing from './ProductListForEditing.svelte'

import NewProductsForm from './ProductsForm/NewProducts.svelte'
import EditProductsForm from './ProductsForm/EditProducts.svelte'
//import StreamVidComponent from './miniComponents/StreamVidComponent-vime.svelte'
import StreamVidComponentVime from './miniComponents/StreamVidComponent-vime.svelte'
import ListBookings from './StreamBookings/listBookings.svelte'
import CreateBooking from './StreamBookings/createBooking.svelte'
import EditBooking from './StreamBookings/editBooking.svelte'

import ListProfiles from './listProfiles.svelte'

import SellerGroupListForEditing from './SellerGroupListForEditing.svelte'

import NewSellerGroupForm from './SellerGroupForm/NewSellerGroup.svelte'

import ChatModeration from './chatModeration/ChatModeration.svelte'

import StripeConnectDash from './StripeConnect.svelte'

import ClientDashboard from './ClientDashboard.svelte'

import ClientNavBar from './miniComponents/ClientNavBar.svelte'

import MicroShop from './microShop.svelte'

import MicroShopLanding from './microShop_landing.svelte'

import CreateMicroShop from './microShopsForm/new.svelte'


import MicroShopHub from  './microshopHub.svelte'



if(document.querySelector('#svelte-client-navbar')){
	new ClientNavBar({
		target: document.querySelector('#svelte-client-navbar')
	});	 
}

if(document.querySelector('#product-picker')){
	new App({
		target: document.querySelector('#product-picker')
	});	 
}

if(document.querySelector('#live-product-picker')){
	new AppLiveList({
		target: document.querySelector('#live-product-picker')
	});	 
}

if(document.querySelector('#live-product-video-overlay')){
	new LiveProductButton({
		target: document.querySelector('#live-product-video-overlay')
	});	
}

if(document.querySelector('#strykin-spotlight')){
	new StrykinSpotlight({
		target: document.querySelector('#strykin-spotlight')
	});	
}

if(document.querySelector('#strykin-not-online')){
	new NotCurrentlyOnlinePage({
		target: document.querySelector('#strykin-not-online')
	});	
}

if(document.querySelector('#adminn')){
	new AdminDashboard({
		target: document.querySelector('#adminn')
	});	
}

if(document.querySelector('.schedule-wrapper')){
	new ComingSoon({
		target: document.querySelector('.schedule-wrapper')
	});	
}


if(document.querySelector('#new-product-svelte')){
	new NewProductsForm({
		target: document.querySelector('#new-product-svelte')
	});	
}

if(document.querySelector('#new-seller-group-svelte')){
	new NewSellerGroupForm({
		target: document.querySelector('#new-seller-group-svelte')
	});	
}

if(document.querySelector('#seller-product-list')){
	new ProductListForEditing({
		target: document.querySelector('#seller-product-list')
	});	
}

if(document.querySelector('#seller-groups-list')){
	new SellerGroupListForEditing({
		target: document.querySelector('#seller-groups-list')
	});	
}

if(document.querySelector('#edit-product-svelte')){
	new EditProductsForm({
		target: document.querySelector('#edit-product-svelte')
	});	
}

if(document.querySelector('#cinema-max-svelte')){
	new StreamVidComponentVime({
		target: document.querySelector('#cinema-max-svelte')
	});	
}

if(document.querySelector('#create-booking-svelte')){
	new CreateBooking({
		target: document.querySelector('#create-booking-svelte')
	});	
}

if(document.querySelector('#edit-booking-svelte')){
	new EditBooking({
		target: document.querySelector('#edit-booking-svelte')
	});	
}

if(document.querySelector('#list-bookings-svelte')){
	new ListBookings({
		target: document.querySelector('#list-bookings-svelte')
	});	
}

if(document.querySelector('#profile-list-svelte')){
	new ListProfiles({
		target: document.querySelector('#profile-list-svelte')
	});	
}

if(document.querySelector('#chat-moderation-svelte')){
	new ChatModeration({
		target: document.querySelector('#chat-moderation-svelte')
	});	
}

if(document.querySelector('#dashboard-svelte')){
	new ClientDashboard({
		target: document.querySelector('#dashboard-svelte')
	});	
}

if(document.querySelector('#stripe-connect-svelte')){
	new StripeConnectDash({
		target: document.querySelector('#stripe-connect-svelte')
	});	
}

if(document.querySelector('#stripe-connect-svelte')){
	new StripeConnectDash({
		target: document.querySelector('#stripe-connect-svelte')
	});	
}

if(document.querySelector('#micro-shop-svelte')){
	new MicroShop({
		target: document.querySelector('#micro-shop-svelte')
	});	
}

if(document.querySelector('#micro-shop-svelte-landing')){
	new MicroShopLanding({
		target: document.querySelector('#micro-shop-svelte-landing')
	});	
}

if(document.querySelector('#new-micro-shop-svelte')){
	new CreateMicroShop({
		target: document.querySelector('#new-micro-shop-svelte')
	});	
}

if(document.querySelector('#mincroshop-hub')){
	new MicroShopHub({
		target: document.querySelector('#mincroshop-hub')
	});	
}




//export default app;