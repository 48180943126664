<script context="module">
	let current;
</script>


<script>
  export let video = 'https://strykin-client-uploads.s3.eu-west-1.amazonaws.com/noID/2256220-1626359111187.mp4' 
  let muted = true;
  let highlight

    function muteOthers() {
    muted = !muted; //toggle
    if (current && current !== highlight){ current.muted = true}
        current = highlight;
    }

</script>

<style>
/* .mute-button {
    position: absolute;
    background: #e4dbdbba;
    padding: 5px 10px;
    border-radius: 8px;
    right: 9px;
    top: 9px;
    border: 1px solid #959494;
} */

.mute-button {
  position: absolute;
  background: #69696987;
  cursor: pointer;
  color: #fafafa;
  z-index: 2;
  padding: 6px 12px;
  border-radius: 9px;
  right: 5px;
  top: 5px;
  border-bottom: solid 1px #2f4f4f78;
  border-top: solid 1px #ffffff61;
  
}
</style>

<div on:click={muteOthers} class='mute-button'>
    {#if muted}
    <i class="fas fa-volume-off"></i> Sound 
    {:else}
    <i class="fas fa-volume-mute"></i>
    {/if}
</div>

<!-- svelte-ignore a11y-media-has-caption -->
<video 
    class="lozad card__video"
    src={video}
    bind:muted 
    playsinline={true}
    autoplay={true}
    loop={true}
    data-loaded={true}
    bind:this={highlight}
    on:click={muteOthers}
    style="visibility: inherit; inset: 0px;
    box-sizing: border-box; padding: 0px; border: none; margin:0
    auto; display: block; min-width:
    100%; max-width: 100%; min-height: 100%; max-height: 100%;"
    >
</video>