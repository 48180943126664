<script>
    import { popover } from '../utils/popper_action.js';
    import Modal from './Modal.svelte'; 

export let product

  const deleteParent = id => {
    fetch(backendUrl + "/public/parentListing/" + id, {
      method: "DELETE",
      headers: {
        Authorization: getStrykinAuth(),
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json()) // or res.json()
      .then(res => {
        loadListings();
      });
  };

  const deleteChild = id => {
    fetch(backendUrl + "/public/childProduct/" + id, {
      method: "DELETE",
      headers: {
        Authorization: getStrykinAuth(),
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json()) // or res.json()
      .then(res => {
        loadListings();
      });
  };

  const editChild = id => {
    window.location = "/childProduct/" + id;
  };

  const editParent = id => {
    window.location = "/products_new/" + id;
  };

  const addChild = id => {
    window.location = `/products/${id}/childproduct/new`;
  };


</script>
<style>
.product-image {
    width: 170px;
    height: 170px;
}

.parent-details-wrapper{
   border: 1px solid #8080804d;
    display: flex;
    margin: 9px 0px;
    position: relative;
    border-radius: 11px;
}

.product-name {
    font-weight: bold;
    font-size: 19px;
}

.product-details-group {
    padding: 15px;
    flex: 1;
}

.button-group {
    display: flex;
}

.product{
    background: #f9f9f9;
}

.mini-btn {
    padding: 4px 11px;
    background: #E0E0E0;
    margin-right: 8px;
    margin-top: 20px;
    border-radius: 6px;
    border: 1px solid #BDBDBD;
    cursor: pointer;
}
.active-info {
    position: absolute;
    top: 6px;
    left: 6px;
    background: #ffffffb8;
    padding: 2px 8px;
    border-radius: 5px;
}

.infoModal {
    background: #1976D2;
    color: white;
    padding: 6px;
    position: absolute;
    border-radius: 50%;
    width: 30px;
    display: flex;
    place-content: center;
    border: solid 1px grey;
    top: 4px;
    right: 4px;
    cursor: pointer;
}

</style>

<li>
    <div class="product">
      <div class="parent-details-wrapper">
        <div 
          on:click={() => editParent(product.id)}
          class="product-image"
          style=" background: url({product.images[0]}) center / cover;" />
        <div class="product-details-group">
          <div class="parent-details">
            <div class='product-name' >{product.name}</div>
            <div class='product-description' >{product.description.slice(0,50)}{product.description.length > 50 ?'...':'' } </div>
            <div class="button-group">
              <div class='mini-btn edit' on:click={() => editParent(product.id)}>
                <i class="fas fa-pen-square" />
                Edit
              </div>
              <div class='mini-btn remove' on:click={() => deleteParent(product.id)}>
                <i class="fas fa-minus-square" />
                Delete
              </div>
              <!-- <div on:click={() => addChild(product.id)}>
                <i class="fas fa-plus-square" />
              </div> -->
            </div>
          </div>

		  <div class='active-info'>
		    {product.active? '🟢 Active':'🔴 Inactive'}
		  </div>
          {#if !product.videos}
            <div class='infoModal' use:popover={{ component: Modal, content:`This product has no video
            Click edit to add video
            `, placment:'left' }} >
                <i class="fas fa-info"></i>
            </div>
          {/if}


        </div>
      </div>
      <!-- <div class="child-container">
        {#each product.ChildProducts as ChildProduct}
          <div class="child">
            <div class="child-name">
              {ChildProduct.childVariable || 'default'}
            </div>
            <div class="button-group">
              <div on:click={() => editChild(ChildProduct.id)}>
                <i class="fas fa-pen-square" />
              </div>
              <div on:click={() => deleteChild(ChildProduct.id)}>
                <i class="fas fa-minus-square" />
              </div>
            </div>
          </div>
        {/each}
      </div> -->
    </div>
</li>