<script>
  import { getContext } from 'svelte';
  import Surprise from './ProfileModal.svelte';

  import { createEventDispatcher } from 'svelte';


  const dispatch = createEventDispatcher();

  export let description;
  export let name;
  export let id; 
  export let image; 


function sayHello(id) {
    dispatch('makeActive',{id});
}

</script>

    <div on:click={sayHello(id)} class="profile">
        <div class='expand-float'><i class="fas fa-ellipsis-h"></i></div>
        <div class="profile-image" style="background-image: url('{image}'); background-size: cover;" ></div>
        <div class="profile-title" >{name}</div>
        <div class='description-intro'>{description.substring(0, 90) +  (description.length > 90 ? '...':'') }</div>
        <div class='tags'>
            <ul>
            </ul>
        </div>
    </div>

    <style>
    .profile-image{
        width:100%;
        height:150px;
        border-radius:15px 15px 0px 0px ;
    }

    .profile{
        text-align: center;
        text-align: -webkit-center;
        position: relative;
    }

    .description-intro {
      margin: 8px;
    }
    .expand-float{
        position: absolute;
        left: auto;
        right: auto;
        width: 100%;
        color: aliceblue;


    }

    .profile-title{
        font-size: 1.2em;
        padding: 7px 0px;
        
    }
</style>

<!-- <p><button on:click={showSurprise}>Show me a surprise!</button></p> -->
