<script>
console.log('voop')

let getUsers = []
let blacklistedIDs = []


const mapBlackListedIds= ()=>{
    getUsers = getUsers.map(user => {
        console.log(blacklistedIDs.findIndex((userids) => userids === user.id) >= 0);
        if(blacklistedIDs.findIndex((userids) => userids === user.id) >= 0){
            console.log('match')
            user.blocked = true;
            return user
        }else{
            user.blocked = false;
            return user
        }


    } )

    console.log(getUsers)
}


$:blacklistedIDs,mapBlackListedIds()


const backendUrl = '__backendUrl__';

var chatUsers = io(backendUrl + '/chatUsers', {
    transports: ['websocket', 'xhr-polling']
});

chatUsers.on('loggedInUsers', function (users) {
    console.log(users);
    getUsers = users;
})

fetch(backendUrl + "/chatmod/chatBlackList")
    .then(response => {
        return response.json();
}).then((blacklist) => {
    blacklistedIDs = blacklist
    mapBlackListedIds();
    }).catch((err)=>{
    console.log(err)
    })

var disable = (id)=>{
    console.log('disable', id )
    fetch(backendUrl + "/chatmod/user/"+ id +"/blacklisttoggle")
    .then(response => {
        return response.json();
    }).then(({blacklist}) => {
        blacklistedIDs = blacklist
      }).catch((err)=>{
        console.log(err)
      })

}


</script>

<div>
    <h1>Chat moderation</h1>

    <ul>
        {#each getUsers as user}
            <li on:click={disable(user.id)}  class='pill'>
            {user.username} ({user.id}) {user.blocked?'blocked':'unblocked'}
            </li>
        {/each}
    </ul>

</div>