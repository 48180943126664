<script>
  const backendUrl = "__backendUrl__";
  //import io from 'sockets.io'
  import ClientNavBar from './miniComponents/ClientNavBar.svelte'
  import {getStrykinAuth} from "./utils/utils.js";

  let name = "world";
  let sellerGroupListings = [];

  const loadListings = () => {
    fetch(backendUrl + "/private/sellerGroup", {
      headers: {
        Authorization: getStrykinAuth(),
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(( sellerGroupListingsRes ) => {
        sellerGroupListings = sellerGroupListingsRes;

      });
  };

  const deleteParent = id => {
    fetch(backendUrl + "/private/sellerGroup/" + id, {
      method: "DELETE",
      headers: {
        Authorization: getStrykinAuth(),
        "Content-Type": "application/json"
      }
    })
    .then(res => res.json()) // or res.json()
    .then(res => {
        loadListings();
    });
  };

  const deleteChild = id => {
    fetch(backendUrl + "/private/liveSwitchClients/" + id, {
      method: "DELETE",
      headers: {
        Authorization: getStrykinAuth(),
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json()) // or res.json()
      .then(res => {
        loadListings();
      });
  };

  const makeActive = clientIds =>{

      console.log(clientIds);

    fetch(`${backendUrl}/private/liveSwitchClients`,
    {
        method: 'POST',
        headers:{
        'Authorization':   getStrykinAuth(),
        'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "clientIds": clientIds,
        }),
    })
    .then((response) => {
        return response.json();
    })

  }

  const editChild = id => {
    window.location = "/childProduct/" + id;
  };

  const editParent = id => {
    window.location = "/products_new/" + id;
  };

  const addChild = id => {
    window.location = `/products/${id}/childproduct/new`;
  };

  loadListings();
</script>

<style>
  .btn {
    /* background: grey; */
	margin-top:8px;
	margin-left:5px;
    padding: 10px 11px;
  }

  .parent-details {
    display: inline-flex;
  }

  .product {
    background: #e0e0e0;
    border: 1px solid black;
    margin: 2px;
  }
  .product:nth-child(even) {
    background: #cecece;
  }

  .child {
    margin: 2px;
    padding: 0px 7px;
    background: white;
    border: 1px solid black;
    display: flex;
    align-items: center;
  }

  .child:nth-child(even) {
    background: #e4e4e4;
  }

  .children {
    padding: 5px;
  }
  .mini-image {
    height: 113px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .product-image {
    height: 55px;
    width: 92px;
    display: inline-block;
  }
  .product-details-group {
    display: inline;
  }
  .button-group {
    display: inline-flex;
  }

  .button-group > div {
    margin: 3px 2px;
    border: 0.5px grey solid;
    padding: 2px;
    background: #ffffffd6;
    border-radius: 2px;
    cursor: pointer;
  }
  .child-name {
    min-width: 35px;
  }
  .parent-details-wrapper {
    display: inline-flex;
  }

</style>

<svelte:head>
  <link
    rel="stylesheet"
    href="https://use.fontawesome.com/releases/v5.8.0/css/all.css" />
  <link
    rel="stylesheet"
    href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
    integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
    crossorigin="anonymous" />
</svelte:head>
<ClientNavBar/>
<h1>Group List</h1>
<div>
  {#each sellerGroupListings as sellerGroup}
    <div class="product">
      <div class="parent-details-wrapper">
        <div class="product-image"/>
        <div class="product-details-group">
          <div class="parent-details">
            <div>{sellerGroup.name}</div>
            <div class="button-group">
              <div on:click={() => editParent(sellerGroup.id)}>
                <i class="fas fa-pen-square" />
              </div>
              <div class='mini-btn' on:click={() => deleteParent(sellerGroup.id)}>
                <i class="fas fa-minus-square" />
              </div>
              <div class='mini-btn' on:click={() => makeActive(sellerGroup.SellerGroup_users.map(user => user.UserId))}>
                <i class="fas fa-play"/>
              </div>
              <!-- <div on:click={() => addChild(sellerGroup.id)}>
                <i class="fas fa-plus-square" />
              </div> -->
            </div>
          </div>
		  <div>
		    <!-- Active:{sellerGroup.active? '🟢':'🔴'} -->
		  </div>
        </div>
      </div>
      <!-- <div class="child-container">
        {#each parentListing.ChildProducts as ChildProduct}
          <div class="child">
            <div class="child-name">
              {ChildProduct.childVariable || 'default'}
            </div>
            <div class="button-group">
              <div on:click={() => editChild(ChildProduct.id)}>
                <i class="fas fa-pen-square" />
              </div>
              <div on:click={() => deleteChild(ChildProduct.id)}>
                <i class="fas fa-minus-square" />
              </div>
            </div>
          </div>
        {/each}
      </div> -->

      <div>
      <ul class='userList'>
      {#each sellerGroup.SellerGroup_users as user}
            <li>
                {user.User.email}
            </li>
        {/each}
      </ul>
      </div>
    </div>
  {/each}
  <div class="add-product-form">
    <a href="/sellergroup/new">
      <div class="btn btn-success">Add group</div>
    </a>
  </div>
</div>
