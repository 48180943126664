<svelte:head>
	<!-- elements go here -->
    <script src="https://sdk.amazonaws.com/js/aws-sdk-2.718.0.min.js" ></script>
    <script src="../../../js/s3_photoExample.js"></script>
    
    <link rel='stylesheet' href='https://unpkg.com/uppload@2.3.0/dist/uppload.css'>
    <link rel='stylesheet' href='https://unpkg.com/uppload@2.3.0/dist/themes/light.css'>
</svelte:head>




<script>
const input = document.getElementById('file-input');
const video = document.getElementById('video');
const videoSource = document.createElement('source');
//let videoSrc = ''
let paused = true;
let file
let fileSize = 0
let videoWidth = 0
let videoHeight = 0

let maxFileSize = 11500000

let uploaded = false;

export let value;

export let videoPreview;


export let userId;

const preview = function() {
  const files = this.files || [];

  if (!files.length) return;
  
  const reader = new FileReader();

  reader.onload = function (e) {
    videoPreview =  e.target.result
    // paused = true
    // setTimeout(()=>{    paused = false }, 30);
    file = files[0]
    fileSize = file.size

    console.log('play', files)

  };
  
  reader.onprogress = function (e) {
    console.log('progress: ', Math.round((e.loaded * 100) / e.total));
  };
  
  reader.readAsDataURL(files[0]);
};

let uploading = false

$: fileSutible =  (((videoWidth/videoHeight) < 0.7) && (maxFileSize > fileSize) && ( fileSize > 20 ))?true:false;

$: ratioS = ((videoWidth/videoHeight) < 0.7) ?true:false;
$: fileSizeB = (maxFileSize > fileSize) ?true:false;
$: fileSizeS = ( fileSize > 20 );


let percUpload = 0;
let handleUploadClick = ()=> {
  if(uploading){
    return
  }

  uploading = true;

  percUpload = 0;
  console.log('FFILLE',file);
          addVideo2([file], userId  || 'noID',`${
          Math.random().toString().replace("0.", "").substr(0, 7)
          }-${new Date().getTime()}.mp4`,'video/mp4')
          .on('httpUploadProgress',function(progress) {
                console.log(progress);
                let newPerc = Math.round(progress.loaded / progress.total * 100);
                if (newPerc != percUpload) {
                  percUpload = newPerc;
                  console.log(percUpload + '% sent');
                }
          })
          .promise()
          .then((data)=>{
            let uploading = false
            console.log('UPLOADED',data)

            value = data.Location

            uploaded = true;
          })
          
}

let foop = (e)=>{
  console.log('videoChange',e);
}

</script>
<style>
.uppload-image{
    width:280px;
    display: flex;
}
.btn{
    margin: 5px;
    padding: 9px 19px;
    font-size: 16px;
    background: #37D2A7;
    color: white;
    border-radius: 7px;
    border: 1px solid grey;
    padding: 5px 11px;
    font-size: 13px;
    display: inline-block;
}

.loader{
  width:100%;
}

.loader-bar{
  height:5px;
  background-image: linear-gradient( 135deg, #602bb6, #dd1173);
  border-radius: 3px;
  transition: width 0.5s;

}
.loader-bar.finished{
    background: #37D2A7;
}

.loader{
  border: 1px solid darkgrey;
  border-radius: 4px;
  width: 280px;
  margin: 7px 0px;
}

#image.hiddenInput{
  display: none;
}

  video{
    display:none;
  }

  .note{
    display: inline-block;
    border: solid 0.5px #b3b3b3;
    padding: 8px 10px;
    margin: 8px 0px;
    background: #0A75CD;
    border-radius: 5px;
    color: white;
  }

  .note.warning{
    background: #fbc02d;
  }
  .note .title{
    display: block;
  }

  .btn {
    margin: 5px;
    padding: 9px 19px;
    font-size: 16px;
    background: #37D2A7;
    color: white;
    border-radius: 7px;
    border: 1px solid grey;
    padding: 5px 11px;
    font-size: 13px;
    display: inline-block;
}
@keyframes pulse {
  0% {
    background-color: #37D2A7;
  }
  100% {
    background-color: #46b597;
  }
}

@keyframes wiggle {
    0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   85% { transform: rotate(5deg); }
   95% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.glowing{
  animation: pulse 0.7s infinite,wiggle 2.5s infinite;

  
  animation-direction: alternate;
}


</style>
<div>
<input  on:change={preview} id="file-input" type="file" accept="video/mp4">

<video src={videoPreview} bind:videoWidth bind:videoHeight bind:paused id="video"  controls></video>

{#if uploading || uploaded }
<div>
{percUpload}%
</div>
<div class="loader">
  <div style="width:{percUpload}% " class="loader-bar {percUpload == 100 ? 'finished':''}">
  </div>
</div>
{/if}


{#if fileSize > maxFileSize}
<div>
<div class='note warning'>
 <span class='title'>Warning: </span>
file is too big max file size is: 11mb</div>
</div>
{/if}
{#if (videoWidth/videoHeight) > 0.7}
<div>

  <div class='note warning'>

    <span class='title'>Warning: </span>
    video needs to be in portrait format
  </div>
</div>
{/if}

<!-- <div>sutible: {fileSutible}</div>
<div>ratioS: {ratioS} {(videoWidth/videoHeight)}</div>
<div>fileSizeB: {fileSizeB}</div>
<div>fileSizeS: {fileSizeS} {fileSize > 20} {fileSize}</div> -->

{#if (!uploaded && fileSutible) }
<div class='btn glowing { uploading?'':'disabled'}' on:click={handleUploadClick}>
	{ uploading?'uploading':'upload video'} 
</div>

{:else if uploaded}
<div class='btn'>
	uploaded
</div>


{/if}

</div>
