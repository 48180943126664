
<div class="carousel">
	<div class="slides" bind:this={siema}>
		<slot></slot>
	</div>
</div>




<style>
	.carousel {
		position: relative;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
	
	button {
		position: absolute;
		width: 40px;
		height: 40px;
		top: 50%;
		z-index: 50;
		margin-top: -20px;
		border: none;
		background-color: transparent;
	}

  button:focus {
    outline: none;
  }
	
	.left {
		left: 2vw;
	}
	
	.right {
		right: 2vw;
	}

	ul {
		list-style-type: none;
		position: absolute;
		display: flex;
		justify-content: center;
		width: 100%;
		margin-top: -30px;
		padding: 0;
	}

	ul li {
		margin: 6px;
		border-radius: 100%;
		background-color: rgba(255,255,255,0.5);
		height: 8px;
		width: 8px;
	}

	ul li:hover {
		background-color: rgba(255,255,255,0.85);
	}

	ul li.active {
		background-color: rgba(255,255,255,1);
	}
</style>

<svelte:head>
	<script src="https://unpkg.com/flickity@2/dist/flickity.pkgd.js" on:load={init}></script>
	<link rel="stylesheet" type="text/css" href="https://unpkg.com/flickity@2/dist/flickity.css">
</svelte:head>

<script context="module">
	
	let siema
	let controller

	const init = () => {
		controller = new Flickity(siema,{
			wrapAround: true,
			imagesLoaded: true
		})

        var cnt = 0
		var resizeInt = setInterval(resize, 500);

		function resize() {
			if(cnt > 4){
				clearInterval(resizeInt);
			}
			cnt++
			controller.resize()
		}
	}

	export function switchSlide(pop) {
		controller.select( pop )
	}
</script>


<script>
	import { onMount, createEventDispatcher } from 'svelte'
	
	export let startIndex = 0




	let currentIndex = startIndex;
	
	let timer

	const dispatch = createEventDispatcher()

	$: pips = controller ? controller.innerElements : []
	
</script>

