<script>
export let errors
export let childProduct
export let handleChange
export let increment
export let childProductsLength
export let remove
export let add
</script>


<style>

  .form-group {
    display: flex;
    align-items: baseline;
  }

  .form-group:first-of-type label {
    font-size: 17px;
  }

  .form-group label {
    display: inline-block;
    font-size: 0px;
  }

  .form-group > div {
    width: min-content;
  }

  button ~ button {
    margin-left: 15px;
  }

  input.shipping,
  input.stock,
  input.rrp,
  input.live-price {
    width: 90px;
  }

  @media only screen and (max-width: 750px) {
  .form-group {
    background-color: #f6f7f7;
    border: #aeaeae 1px solid;
    padding: 15px 9px;
    display: grid;
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 4px 0px;
    grid-template-areas: 
    "variation-name variation-name"
    ". ."
    ". ."
    ". ."
    "action-btn action-btn"
    ; 
    margin: 13px 0px;
    border-radius: 12px;
  }

  .variation-name { grid-area: variation-name; }
  .action-btn { grid-area: action-btn; }


  

   .form-group label {
    font-size: 17px;
    
  }

  .remove-text{
      display: none;
  }

  .remove:after{
      content:'Remove variation';
  }

  .add-text{
      display: none;
  }
  .add:after{
      content:'Add variation';
  }

  .btn.remove{
        margin: 0px;
        margin-top: 11px;
  }
  .btn{
    display: block;
    margin: 22px;
    padding: 9px 19px;
    font-size: 15px;
    width: max-content;
    background: #E0E0E0;
    color: #1d1d1d;
    border-radius: 7px;
    border: 1px solid grey;
  }

}

</style>

<div class="form-group">
          <div class='variation-name'>
          <label>Product variation
            <input
              name={`ChildProducts[${increment}].childVariable`}
              placeholder="childVariable"
              on:change={handleChange}
              on:blur={handleChange}
              bind:value={childProduct.childVariable}
            />
            </label>
            {#if errors && errors.childVariable}
              <small class="error YO!">{errors.childVariable}</small>
            {/if}
          </div>

          <div>
          <label>
          Price
            <input
              step=".01"
              placeholder="livePrice"
              name={`ChildProducts[${increment}].livePrice`}
              on:change={handleChange}
              on:blur={handleChange}
              bind:value={childProduct.livePrice}
              type=number
              class='live-price'
            />
          </label>
            {#if errors && errors.livePrice}
              <small class="error">{errors.livePrice}</small>
            {/if}
          </div>

          <div>
          <label>Rrp
            <input
              step=".01"
              placeholder="Rrp"
              name={`ChildProducts[${increment}].rrp`}
              on:change={handleChange}
              on:blur={handleChange}
              bind:value={childProduct.rrp}
              type=number
              class='rrp'
            />
            </label>
            {#if errors && errors.rrp}
              <small class="error">{errors.rrp}</small>
            {/if}
          </div>

          <div>
          <label>Stock
            <input
              placeholder="stock"
              name={`ChildProducts[${increment}].stock`}
              on:change={handleChange}
              on:blur={handleChange}
              bind:value={childProduct.stock}
              type=number
              class="stock {childProduct.stock < 1 ? 'zero-stock': '' }"
            />
            </label>
            {#if errors && errors.stock}
              <small class="error">{errors.stock}</small>
            {/if}
          </div>

          <div>
            <label>UK P&P
            <input
              step=".01"
              placeholder="localShippingCost"
              name={`ChildProducts[${increment}].localShippingCost`}
              on:change={handleChange}
              on:blur={handleChange}
              bind:value={childProduct.localShippingCost}
              type=number
              class='shipping'
            />
            </label>
            {#if errors && errors.localShippingCost}
              <small class="error">{errors.localShippingCost}</small>
            {/if}
          </div>

          <div>
          <label>EU P&P
            <input
              step=".01"
              placeholder="europeanShippingCost"
              name={`ChildProducts[${increment}].europeanShippingCost`}
              on:change={handleChange}
              on:blur={handleChange}
              bind:value={childProduct.europeanShippingCost}
              type=number
              class='shipping'
            />
            </label>
            {#if errors && errors.europeanShippingCost}
              <small class="error">{errors.europeanShippingCost}</small>
            {/if}
          </div>

          <div>
          <label>Int. P&P
            <input
              step=".01"
              placeholder="internationalShippingCost"
              name={`ChildProducts[${increment}].internationalShippingCost`}
              on:change={handleChange}
              on:blur={handleChange}
              bind:value={childProduct.internationalShippingCost}
              type=number
              class='shipping'
            />
          </label>
            {#if errors && errors.internationalShippingCost}
              <small class="error">{errors.internationalShippingCost}</small>
            {/if}
          </div>

          



          {#if childProductsLength !== 1}
          <div class="action-btn">
                <button class='remove btn' type="button" on:click={remove(increment)}>
                <span class='remove-text'>-</span>
                </button>
          </div>
          {/if}

        </div>
        {#if increment === childProductsLength - 1}
          <button class='add btn' type="button" on:click={add}>
          <span class='add-text'>+</span>
          </button>
        {/if}
        
  