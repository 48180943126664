<style>
    .live-text{
        color: black;
        font-size:16px;
    }

    .live-live-prod{
        width: 100%;
    }

    .button-look{
        display: grid;
    }

    @keyframes liveglow {
    0% {
        background: #E64235;
    }

    100% {
        background:#FF24E6;
      }
    }

    .live-glow {
        animation: liveglow 1.5s infinite;
        animation-direction: alternate;
        background: red;
        color: white;
        display: block;
        padding: 1px 6px;
        font-family: 'Oswald', sans-serif;
        font-weight: 700;
        text-align: center;
        letter-spacing: 3px;
        font-size: 12px;
        width: 100%;
        justify-content: center;
        margin-right: 4px;
        text-transform: uppercase;
    }
    .live-btn-style{
        display: flex;
        flex-flow: column;
    }


    @media only screen and (max-width: 600px) {
        .live-glow {
            font-size: 10px;
        }
        .live-glow.top{
            display: none;
        }
        .live-btn-style{
            flex-direction: column-reverse;
        }
    }


</style>

<script>
    import {switchSlide} from './miniComponents/Carousel.svelte'
    import { activeProductId, trigger, stockList2 } from './stores.js';


    const backendUrl = '__backendUrl__';

    let liveProd ={};
    let stockList = [];
    let hiddenActive = {}

    
    const triggerProdSwitch = ()=>{
        trigger.update(n => n + 1); // update store to trigger product switch hack
        document.querySelector('.active-product').scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    var productSocket = io(backendUrl + '/productStream', {
        transports: ['websocket', 'xhr-polling']
    });


    productSocket.on('activeProduct', function (item) {
        hiddenActive = item.listingDetails;
        if (item.listingDetails && item.listingDetails.id){
            if (    stockList.find(element => element.id === item.listingDetails.id)  ){
                liveProd = item.listingDetails;
            }
        }
    });

    
    stockList2.subscribe(value => {
        if (value.find(element => element.id === hiddenActive.id) ){
                liveProd = hiddenActive;
        }
        stockList = value;
	});



</script>
{#if liveProd.images}
<div class="button-look" on:click={triggerProdSwitch}>
    <div on:click={triggerProdSwitch} class='live-product-video-overlay'>
        <div class="live-button-look" ></div>
        <div class='live-btn-style' >
            <div class='live-glow top'>Click To</div>
            <img class='live-live-prod' alt="A man dances." src={liveProd.images[0]}>
            <div class='live-glow '>View</div>
        </div>
    </div>
</div>
{/if}