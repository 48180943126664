<script>
  const backendUrl = "__backendUrl__";
  import moment from 'moment'
  import ClientNavBar from '../miniComponents/ClientNavBar.svelte'
  import {getStrykinAuth} from "../utils/utils.js";

  let name = "world";
  let streamListings = [];


  	function destroy(id) {
      fetch(backendUrl + "/private/stream/" + id, {
      method: "DELETE",
      headers: {
        Authorization: getStrykinAuth(),
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json()) // or res.json()
      .then(res => {
        location.reload();
      });
    }



  const loadListings = () => {
    fetch(backendUrl + "/private/stream", {
      headers: {
        Authorization: getStrykinAuth(),
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        return response.json();
      })
      .then(( streamListing ) => {
        console.log(streamListing);
        streamListings = streamListing;
      });
  };

  const addChild = id => {
    window.location = `/products/${id}/childproduct/new`;
  };

  loadListings();
</script>

<style>
  .btn {
    /* background: grey; */
	margin-top:8px;
	margin-left:5px;
    padding: 10px 11px;
  }

  .parent-details {
    display: inline-flex;
  }

  .product {
    background: #e0e0e0;
    border: 1px solid black;
    margin: 2px;
  }
  .product:nth-child(even) {
    background: #cecece;
  }

  .child {
    margin: 2px;
    padding: 0px 7px;
    background: white;
    border: 1px solid black;
    display: flex;
    align-items: center;
  }

  .child:nth-child(even) {
    background: #e4e4e4;
  }

  .children {
    padding: 5px;
  }
  .mini-image {
    height: 113px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .product-image {
    height: 55px;
    width: 92px;
    display: inline-block;
  }
  .product-details-group {
    display: inline;
  }
  .button-group {
    display: inline-flex;
  }

  .button-group > div {
    margin: 3px 2px;
    border: 0.5px grey solid;
    padding: 2px;
    background: #ffffffd6;
    border-radius: 2px;
    cursor: pointer;
  }
  .child-name {
    min-width: 35px;
  }
  .parent-details-wrapper {
    display: inline-flex;
  }


.date{
  font-size:1.7em
}
    .date{
  font-size:1.7em
}

.event{
  color:#292929;
  background: #F0F0F0;
  min-height: 170px;
  margin:4px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);


}
/* hr{
    border-top: 1px solid #FF24E6;
} */

.event .title{
    font-size:2em;
}

@media only screen and (min-width: 600px) {
 .event .title{
    font-size:1.7em;
 }
}

.event .times{
    font-size:1.2em;
    font-weight:700;
  }

.event .description {
    max-width: 430px;
}

.info{
    padding: 7px 21px;
    font-size: 14px;
}

.image{
  background:red;
  width: 190px;
  height: 190px;
}
.event{
   display: flex;
}

/* .event.even {
  background: #BA00FF;
}

.event.odd {
  background: #7B01CA; 
} */

  .submision-note {
    background: #d6efdb;
    padding: 13px;
    margin: 12px 0px;
    border-radius: 18px;
    border: grey solid 0.2px;
  }

.remind-me.google {
    background: #3AFECA;
    display: inline-block;
    color: black;
    padding: 3px 14px;
    margin: 2px;
        margin-top: 8px;
    border-radius: 28px;
    cursor: pointer;
}

.remind-me.google:hover {
    background: #3CCBC5;
}
.list-info{
  padding:10px;
}

.page-wrap {
    max-width: 900px;
    margin: 17px auto;
    padding: 0px 18px;
}


h2 {
    font-size: 1.2rem;
    margin-top: 40px;
}
.edit{
  background: #626262;
  color:white;
  border-color: grey;
}
.delete{
  border: 2px solid #626262;
  background: none;
  color:#626262;
}
.info .btn {
    font-size: 14px;
    padding: 9px;
    width: 87px;
}

</style>

<svelte:head>
  <link
    rel="stylesheet"
    href="https://use.fontawesome.com/releases/v5.8.0/css/all.css" />
  <link
    rel="stylesheet"
    href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
    integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
    crossorigin="anonymous" />
</svelte:head>
<ClientNavBar/>
<div class='page-wrap'>

  <div class='list-info'>
    <div>
      <h1>Your Personal streams</h1>
      <p>
        You can click below to create a new stream. You are also welcome to edit one of your previous streams if you think there is a mistake to fix or it’d be easier for you to use information there for your new stream. If you are taking part in a group stream it will not be listed here.
      </p>
    </div>

    <div class="add-product-form">
      <a href="./streamList/new">
        <div class="btn btn-success">Create new stream</div>
      </a>
    </div>
  </div>

  <div>
    {#each streamListings as streamListing}
    <h2>{moment(streamListing.startTime).format('dddd Do MMMM')}</h2>
        <div class='event {streamListing.type}'>
          <div class='image' style='background:url({streamListing.image}); background-size:contain; background-repeat: no-repeat; background-color: white; background-position: center;'></div>
          <div class='info' >
            <div class='title'>
              {streamListing.name}
            </div>
            <div class='times'>
              {moment(streamListing.startTime).format('h:mma')} - 
              {streamListing.endTime ?  moment(streamListing.endTime).format('h:mma'): moment(streamListing.startTime).add(1, 'hour').format('h:mma') } (BST)
            </div>
            <div class='description'>
                {streamListing.description}
            </div>
            <div class='button-group'>
                <a href={"./streamListing/"+ streamListing.id} >
                  <div class="btn edit btn-success">Edit</div>
                </a>
                <a href="javascript:void(0);">
                  <div class="btn delete btn-success" on:click={()=>{destroy(streamListing.id)}}>Delete</div>
                </a>
            </div>
          </div>
        </div>
    {/each}

  </div>

</div>