<!-- https://github.com/sveltejs/svelte-virtual-list -->

<script>
	import VirtualList from './VirtualList.svelte';
	import fetchList from './data.js';
	import ListItem from './ListItem.svelte';

	import { onMount } from "svelte";
	import ClientNavBar from './miniComponents/ClientNavBar.svelte'
	import {getStrykinAuth} from "./utils/utils.js";

	const backendUrl = '__backendUrl__';

	let items = [];

	let searchTerm = "";
	
	$: filteredList = items.filter(item => item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
	
    let start;
	let end;
	
	console.log(backendUrl)

  onMount(async function() {
	const response = await fetch( backendUrl + '/public/getActiveProducts',
	{headers:{
		'Authorization':  getStrykinAuth(),
		'Content-Type': 'application/json'
	}}).then((response) => response.json());

	console.log(response)
	items = response
	.filter(item => item.active)
	.map((item)=>{
		return {
			id:item.id,
			description:item.description,
			name:item.name,
			images:item.images
		}
	});
	
  });
	
</script>
<link rel="stylesheet" href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css'>
<ClientNavBar/>
<main>

	<h1>Live Item Listx</h1>
	<p>Pick the item you want live</p>

	Filter: <input bind:value={searchTerm} />
	{searchTerm}

	<div class='container'>
		<VirtualList items={filteredList} bind:start bind:end let:item>
			<ListItem {...item}/>
		</VirtualList>
		<div class='footer-page'>showing items {start}-{end}</div>
	</div>
</main>

<style>
	.container {
		border-top: 1px solid #333;
		border-bottom: 1px solid #333;
		min-height: 200px;
		height: calc(100vh - 10em);
		margin-top: 5px;
	}
	.footer-page{


	}
	main {
    	margin: 8px;
  	}
</style>