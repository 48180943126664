<style>
    ul {
        list-style: none;
        margin-bottom: 0px;
        background-image: linear-gradient(135deg, #602bb6, #dd1173);

    }
    li {
        display: inline-block;
        margin-left:10px;
        background: #f9b4c057;
    }
    a{
        padding: 0px 8px;
        color: white;
        font-family: sans-serif;
        font-size:16px;
        font-weight: 400;
    }

</style>
<script>
    let userType = sessionStorage.getItem('userType');
</script>
<ul>
    <li>
        <a href="/dashboard">Dashboard</a>
    </li>
    <li>
        <a href="/products_new">Your Products</a>
    </li>
    <li>
        <a href="/microshop/update">Your Microshop</a>
    </li>
    <li>
        <a href="/productList">Live product controller</a>
    </li>

    <li>
        <a href="/streamList">Your Personal Streams</a>
    </li>
    <li>
        <a href="/sellers/orders">Orders</a>
    </li>
    <li>
        <a href="/stripeConnect">Stripe Connect</a>
    </li>
    <!-- <li>
        <a href="https://community.strykin.com/feed">Community</a>
    </li> -->
    <li>
        <!-- <a href="/stripe_connect">Stripe Connect</a> -->
    </li>
    {#if userType=='admin'}
        <a href="/adminjigaz">Admin</a>
        <a href="/streamList">Your Streams</a>
        <a href="/LiveProductList">Live prod list</a>
        <a href="/sellergroup">Seller groups</a>
        <a href="/chatmoderation">Chat moderation</a>
    {/if}
</ul>
