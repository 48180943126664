<script>
  import StrykinProductCard from './StrykinProductCard.svelte';

  import Carousel, {switchSlide} from './miniComponents/Carousel.svelte'
  import LiveProductButton from './LiveProductButton.svelte';

  import { trigger, stockList2 } from './stores.js';

    let stockList = []

    let stockWithLiveOrders = []


    let activeProductId = '0';

    stockList2.subscribe(value => {
		stockList = value;
	});


    //dfsdfsdfsdfv ------------- sfdfsdf

    const backendUrl = '__backendUrl__';

    var productSocket = io(backendUrl + '/productStream', {
        transports: ['websocket', 'xhr-polling']
    });


    var clientSocket = io(backendUrl + '/activeClient', {
        transports: ['websocket', 'xhr-polling']
    });

    productSocket.on('activeProduct', function (item) {
        if (item.listingDetails && item.listingDetails.id){
        //console.log('UPDATED_IDxx', item.listingDetails.id)
             activeProductId = item.listingDetails.id;
        //console.log('UPDATED_ID_2xx',$activeProductId)
        }
    });

    clientSocket.on('activeClient', function (newStockList) {
      stockList2.set([]);
      setTimeout(function(){ // This Re-triggers 
          stockList2.set(newStockList);
       }, 30);
    });




    const productCard = {
        listingDetails:{},
        children:[],
        activeChildIndex:0,
        timeinterval:{}
    }

    let productLiveInfo = []

    let liveProdStats =  {}

    const updateProdStat = ()=>{
        if(productLiveInfo[0] && productCard.children[0]){
            liveProdStats = productLiveInfo.find((el)=> el.childProductid === productCard.children[productCard.activeChildIndex].id)
            return liveProdStats;
        }
    }


    const testFunction =()=>{

        const indexOfLive = stockWithLiveOrders.findIndex((el)=> el.id === activeProductId)
        if(indexOfLive >= 0){
            switchSlide(indexOfLive)
        }


    }

    $:productLiveInfo,mapLiveStock()
    $:stockList,mapLiveStock()

    $:$trigger,testFunction();


    productSocket.on('activeProducts', function (stockList) {
      productLiveInfo = stockList;
    });

    const mapLiveStock = ()=>{
        if(stockList.length > 1){
            stockWithLiveOrders = stockList.map((parentListing)=>{
                let revisedChildren = parentListing.ChildProducts.map((childProduct) =>{
                    //hashmapwouldspeedthis up 10X
                    childProduct.currentLiveStats = productLiveInfo.find((el)=> childProduct.id === el.childProductid)
                    
                    return childProduct

                });
                parentListing.ChildProducts = revisedChildren

                

                return parentListing
            })

        }

    }

    // productSocket.on('activeProduct', function (item) {
    //     item.children.sort((a, b) => (a.id > b.id)  ? 1 : -1 ) 
    //     item.children.sort((a, b) => (a.livePrice > b.livePrice)  ? 1 : -1 )
    //     item.children.sort((a, b) => (a.childVariable > b.childVariable) ? 1 : -1)

    //     if(productCard.listingDetails.id != item.listingDetails.id){
    //         productCard.activeChildIndex = 0;
    //     }
    //     if (item && item.children && item.children[0]) {
    //         productCard.listingDetails = item.listingDetails
    //         productCard.children =  item.children
    //     }
    // });

</script>

<style>
	.slide-content {
		border:1px solid;
        background: palevioletred;
        min-height: 80px;
	}

    .carousel-container{
        width:360px;
    }

    .offline-card{
       /* -- background: red; */
        width:100%;
        padding:2px;
        text-align: -webkit-center;
    }
    h1{
        font-family: Oswald, sans-serif;
        text-align: center;
    }

    .offline-details{
        /* background:green; */
        padding:0px 15px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .spotlight-hero-image{
      height: 300px;
      background:url('https://styrkin.s3-eu-west-1.amazonaws.com/public/site-assets/124296429_658199881729020_7857777123023613718_n.gif');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .blue{
        
      filter: hue-rotate(-232deg);
    }


</style>

<div class="carousel-container">



    {#if stockList[0]}
    <Carousel stockList={stockList}  perPage={1} >

            {#each stockWithLiveOrders as productCardx, i}
                <StrykinProductCard
                productCard={productCardx} 
                liveProdStats={liveProdStats}  
                productLiveInfo={productLiveInfo} 
                />
            {/each}

    </Carousel>

    {:else}
    <div class='offline-card' >
        <div class='spotlight-hero-image'></div>
        <!-- <h1>We are a Christmas shopping experience with a heart.</h1> -->
        <div class='offline-details'>
        <strong>Join in our QVC style live shopping events to watch & shop talented creative and handmade businesses.</strong>
          <hr>
         Meet talented makers, watch as they show & tell stories about their products and shop something special.<span class='blue'>🎁</span>
        </div>
    </div>

    {/if}

    <!-- <Carousel  perPage={1} >
        {#if stockList[0]}
            {#each stockList as productCardx, i}
                <StrykinProductCard
                productCard={productCardx} 
                liveProdStats={liveProdStats}  
                productLiveInfo={productLiveInfo} 
                />
            {/each}
        {/if}

    </Carousel> -->
 

    <!-- <div class="carousel" >
        {#if stockList[0]}
            {#each stockList as productCardx, i}
                <StrykinProductCard
                productCard={productCardx} 
                liveProdStats={liveProdStats}  
                productLiveInfo={productLiveInfo} 
                />
            {/each}
        {/if}
    </div> -->



</div>