

<script>
   import moment from 'moment'

   export let value;
   export let timesTaken;

   let avalibleSlots = [{
       value:'18:00:00',
       displayTime:'6:00 pm'
   },{
       value:'19:00:00',
       displayTime:'7:00 pm'
   },{
       value:'20:00:00',
       displayTime:'8:00 pm'
   },{
       value:'21:00:00',
       displayTime:'9:00 pm'
   },{
       value:'22:00:00',
       displayTime:'10:00 pm'
   }]


let formatedTimesForSubtraction =[]
let currentAvalibleSlots = avalibleSlots;

   const updateSlots = ()=>{
        formatedTimesForSubtraction = timesTaken.map(x=>moment(x).format('h:mm a'));
        console.log('formatedTimesForSubtraction',formatedTimesForSubtraction);
        currentAvalibleSlots = avalibleSlots.filter(x=>( formatedTimesForSubtraction.findIndex(y=> x.displayTime === y)))
       }
   
   $: timesTaken, updateSlots();

   const selectBooking = (pickedTime,i)=>{
       console.log(pickedTime)
       currentAvalibleSlots.map(x=>x.active = false)

       currentAvalibleSlots[i].active = true

       value = pickedTime;

   }

   $: value, (()=>{
    if(value){
        console.log(avalibleSlots)
       let i = avalibleSlots.findIndex((item)=>{
           return item.value === value
       })

       if(i>= 0 ){
         selectBooking(value,i)
       }
    }

   })();

   


</script>
<style>


.btn{
    display: inline-block;
    margin: 4px;
    padding: 9px 19px;
    font-size: 14px;
    background: #a9a8a8;
    color: white;
    border-radius: 7px;
    border: 1px solid grey;
    cursor: pointer;
}

.btn:hover{
    background: #727070;
}

.btn.active{
    background: #37d2a7;
}
</style>

<div>
{#if avalibleSlots[0]}
Avalible Times
<br/>
  {#each currentAvalibleSlots as currentAvalibleSlot, i}
     <div class="time-pick btn {currentAvalibleSlot.active ? 'active':''}"  on:click={() => selectBooking(currentAvalibleSlot.value,i)}>
     {currentAvalibleSlot.displayTime}
     </div>
  {/each}
  {/if}
</div>

